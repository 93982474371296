@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;
  --font-family-data-display: 'Roboto', sans-serif;
  --big-header-font-family: 'Impact', Haettenschweiler, 'Arial Narrow ', sans-serif;
  --gradient-text: linear-gradient(35deg, #ff0d0d 0%, #7fbbff 76%);
  --gradient__text-ebook: linear-gradient(45deg, #ff0d0d 0%, #7fbbff 90%);

  --pricing-gradient-text: linear-gradient(45deg, #ff0d0d 45%, #7fbbff 55%);
  --gradient-bar: linear-gradient(35deg, rgba(255, 0, 0, 1) 50%, rgba(0, 134, 240, 1) 50%);

  --gradient-separator: linear-gradient(90deg, rgb(255, 88, 88) 35%, rgb(127, 187, 255) 75%);
  --gradient-separator-left: linear-gradient(180deg, rgb(255, 88, 88) 35%, rgb(127, 187, 255) 75%);
  --color-bg: #040C18;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;

  --toastify-icon-color-success: #05910a;

  --toastify-color-progress-light: linear-gradient(to right,

      #007bff88,
      #0050a58f,
      #ff000098,
      #ff000088);

  --toastify-color-progress-info: linear-gradient(to right,
      #007bff88,
      #0050a58f,
      #ff000098,
      #ff000088);
  --toastify-color-progress-success: linear-gradient(to right,
      #007bff88,
      #0050a58f,
      #ff000098,
      #ff000088);

  --toastify-color-progress-error: linear-gradient(to right,
      #007bff88,
      #0050a58f,
      #ff000098,
      #ff000088);
}

.Toastify__toast-body {


  min-width: 80%;
}

.Toastify__close-button {
  padding-right: 4px;
  justify-content: end;
  color: var(--color-text);
}

.gradient__bg {


    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);


}