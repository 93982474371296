.win_percentage {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    -webkit-font-smoothing: antialiased;
    stroke: #000000;
    stroke-width: 1px;

}


.center_header {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    -webkit-font-smoothing: antialiased;
    stroke: #000000;
    stroke-width: 1px;

}

.pie_center-header {
    -webkit-font-smoothing: antialiased;
    stroke: #000000;
    stroke-width: 1px;
    font-weight: 750;
    font-size: 23px;
    font-family: var(--big-header-font-family);
    text-decoration: underline;
    text-decoration-thickness: 3.5px;
    text-shadow: 1px 0px 1px #ff0000;

    -webkit-text-stroke: #000000b0 1px;

}

.pie_center-text {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    -webkit-font-smoothing: antialiased;
    stroke: #000000;
    stroke-width: 1.5px;
    font-weight: 750;
    font-size: 34px;
    animation: pulse 600ms linear 600ms infinite alternate forwards;
    align-self: center;
    justify-self: center;

}



.iq__chart-win_chance_text {

    overflow: visible;
    text-decoration: underline;
    text-decoration-thickness: 3.5px;
    font-family: Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 21px;
    font-weight: 750;
    stroke: black;
    stroke-width: .75px;



}

.iq__chart-winner {
    font-weight: 750;
    flex-wrap: wrap;
    font-family: Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    stroke: black;
    stroke-width: .5px;
    font-size: medium;


}

.iq__chart-fighter_stats {
    font-size: medium;

}





.iq__charts-prediction_chart h2 {
    display: flex;
    justify-content: center;
    color: #FFF;
    font-size: 60px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    min-height: 260px;
}

.iq__charts-prediction_chart h3 {
    margin: 0rem 0rem;


}








@media screen and (max-width: 1250px) {
    .all_chart text.pie_center-header {
        font-size: 25px;
    }



    .all_chart text.pie_center-text {
        font-size: 33px;
    }


}

@media screen and (max-width: 1050px) {
    .all_chart text.pie_center-header {
        font-size: 25px;
    }



    .all_chart text.pie_center-text {
        font-size: 35px;
    }


}

@media screen and (max-width: 900px) {
    .all_chart text.pie_center-header {
        font-size: 25px;
    }



    .all_chart text.pie_center-text {
        font-size: 34px;
    }


}


@media screen and (max-width: 800px) {


    .all_chart text.pie_center-header {
        font-size: 22px;
    }

    .all_chart text.pie_center-text {
        font-size: 29px;
    }



}

@media screen and (max-width: 750px) {

    .iq__chart-win_chance_text {

        font-weight: 750;
        stroke: black;
        stroke-width: .5px;
    }

    .all_chart text.pie_center-header {
        font-size: 21px;

    }

    .all_chart text.pie_center-text {
        font-size: 28px;
        transform: translateY(-10px);
        stroke: #000000;
        stroke-width: 1.25px;

    }







}

@media screen and (max-width: 650px) {



    .all_chart text.pie_center-header {
        font-size: 20px;

    }







}

@media screen and (max-width: 600px) {


    .all_chart text.pie_center-header {
        font-size: 18px;

    }

    .all_chart text.pie_center-text {
        font-size: 26px;



    }






}

@media screen and (max-width: 550px) {

    .all_chart text.pie_center-header {
        font-size: 18px;

    }

    .all_chart text.pie_center-text {
        font-size: 25px;



    }
}

@media screen and (max-width: 500px) {

    .all_chart text.pie_center-header {
        font-size: 16px;

    }

    .all_chart text.pie_center-text {
        font-size: 24px;



    }
}

@media screen and (max-width: 480px) {

    .all_chart text.pie_center-header {
        font-size: 16px;

    }

    .all_chart text.pie_center-text {
        font-size: 23px;
        transform: translateY(-12px);


    }
}

@media screen and (max-width: 425px) {

    .all_chart text.pie_center-header {
        font-size: 15px;

    }

    .all_chart text.pie_center-text {
        font-size: 22px;



    }
}

@media screen and (max-width: 400px) {
    .all_chart text.pie_center-header {
        font-size: 14px;

    }

    .all_chart text.pie_center-text {
        font-size: 21px;
        transform: translateY(-10px);
        stroke: #000000;
        stroke-width: 1.25px;
        transform: translateY(-1rem);

    }
}

@media screen and (max-width: 350px) {
    .all_chart text.pie_center-header {
        font-size: 12px;

    }

    .all_chart text.pie_center-text {
        font-size: 16px;
        transform: translateY(-1.25rem);
        stroke: #000000;
        stroke-width: 1px;


    }
}

@keyframes pulse {
    0% {
        text-shadow: #FFF 0px 0px 1px;
        opacity: 60%;

    }


    100% {
        text-shadow: #FFF 0px 0px 3px;


    }


}