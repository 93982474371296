.pwd_new-container {
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    color: var(--color-text);
    margin: auto;
    margin-top: 4rem;
    margin-bottom: 4rem;

    align-items: center;
    flex-wrap: wrap;
}

.pwd_new-header {
    max-width: 300px;
    text-align: center;
    align-content: start;
    margin: auto;
}

.pwd_new-header p {

    margin-bottom: 1rem;
    font-size: 15px;
}

.pwd_new-header h1 {
    font-size: 28px;
    letter-spacing: .2rem;
    margin-bottom: -2px;
}

.pwd_new-input {
    display: flex;
    flex-direction: column;
}

.pwd_new-input label {
    padding-left: 3px;
    font-size: 16px;
}

.pwd_new-input input {
    font-family: 'Nunito', sans-serif;
    font-size: 20px;
    padding: 0.25rem;
    border-radius: 0.5rem;
    background-color: var(--color-footer);
    color: var(--font-color);
    line-height: 28px;
    border: 1px solid transparent;
    outline: none;
}

.pwd_new-input input::placeholder {
    font-size: 15px;

}

.pwd_new-input input:focus {
    border: 1px solid white;
    outline: none;
}

.pwd_new-input label:last-of-type {
    margin-top: .5rem;
}

.pwd_new-input button {
    max-width: 50%;
    font-size: 18px;
    margin: auto;
    margin-top: 1rem;
}

.pwd_new-form {
    display: flex;
    flex-direction: column;
    border: solid 3px rgb(255, 255, 255);
    justify-content: space-evenly;
    border-radius: 2rem;
    box-shadow: 0 0 10px 1px #ffffff;
    max-width: fit-content;


    padding: 3rem;
    background: var(--color-footer);

    padding-top: 2rem;

    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');







.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}



a,
a:visited {
    color: #fff;
}

input[type="password"],
button,
textarea {
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    padding: 0.25rem;
    border-radius: 0.5rem;
    max-width: inherit;
}


.pwd_new-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pwd_new-container h1 {
    font-size: xx-large;
    font-family: var(--big-header-font-family);
    margin-top: 0;
    -webkit-text-stroke-width: 1.25px;
    -webkit-text-stroke-color: black;
    cursor: default;

}

.pwd_new-container div {

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    max-width: 300px;
}

.pwd_new-container div p {

    max-width: inherit;
    max-height: inherit;
}



.pwd_new-container button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
}

.exitRegister {
    display: flex;
    justify-content: right;

}

.exitRegister span {
    display: flex;
    cursor: pointer;
}

.line label {
    cursor: pointer;
}


label {
    color: white;
}



button {
    padding: 0.5rem;
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;


}

.instructions>svg {
    margin-right: 0.25rem;
}


.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;

}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;

}

.tos_agreement {
    text-align: center;
    color: var(--color-text);
    font-size: 12px;
    width: 80%;
}

@media screen and (max-width: 525px) {
    form.register_form {

        margin: 0 1.5rem;


    }
}