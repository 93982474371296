.tos-content_content {
    text-align: center;

    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    font-family: var(--font-family);

}

.tos-content_header {
    padding-top: 4rem;
    text-align: center;
}

.tos-content_header h2 {
    font-family: "Arial Black", sans-serif;
}

.tos-content_content h3 {


    margin-top: 1rem;
}

.tos-content_content p {

    margin-bottom: 1rem;
    margin: 1rem 4rem;
}

.tos-content_info {
    padding: 2rem 0;


}

.tos-content_info h3 {
    color: azure;
}