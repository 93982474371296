.pay_page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 4rem;
    padding-top: 2rem;



}

.pay_page h2 {
    color: white;
    padding-bottom: .5rem;
    font-size: 40px;
    font-family: var(--big-header-font-family);
    letter-spacing: 2px;
    word-spacing: 3px;
}

.pay_page p {
    color: var(--color-text);
    padding-bottom: 2rem;
    font-size: larger;
    text-wrap: wrap;
    margin: 0 1rem;
    text-align: center;
}

.pay_page form label {
    font-size: larger;
    color: white;
    width: 250px;
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
}

.pay_form input {
    min-height: 30px;
    font-size: 14px;

    padding: 0 2px;
    line-height: 27px;
    border-radius: 5px;
    outline: none;
    color: var(--color-text);
    background-color: var(--color-footer);
    border: 2px solid var(--color-footer);
}

.pay_form .checkout_summary option {

    background-color: var(--color-footer);
    color: white;
    font-size: 16px;






}
.pay_form .checkout_summary .coupon_input {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    margin: auto;
    align-content: center;




}

.pay_form .checkout_summary .coupon_input label {
 font-size: 16px;


}

.pay_form .checkout_summary .coupon_input input{
    min-height: 40px;
    font-size: 16px;
    line-height: 27px;
    width: inherit;
    background: var(--color-footer);
    border: 2px solid var(--color-text);
    padding: 0 1rem;
    outline: none;
    color: #FFFFFF;
}

.pay_form .country_zip select {
    line-height: 27px;
    min-height: 30px;
    padding-left: 5px;
    border-radius: 5px;
    background-color: var(--color-footer);
    color: var(--color-text);
    border: 2px solid var(--color-footer);
}

.pay_form .billing_input {
    flex-direction: column;
    display: flex;
    justify-items: center;
    align-items: center;

}

.pay_form .billing_input img {
    width: 350px;
    margin: 0rem 2rem;

}

.pay_form .billing_input .name_info {
    flex-direction: row;
    display: flex;

}

.pay_form .city_state {
    flex-direction: row;
    display: flex;
}

.pay_form .country_zip {
    flex-direction: row;
    display: flex;
}

.pay_form .address_div {


    display: flex;
    flex-direction: column;
    width: 100%;

}

.pay_form .address_div label {


    display: flex;
    flex-direction: column;
    width: auto;
}

.pay_form {
    margin: 2rem 2rem;
    padding: 2rem 2rem;
    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 45%, rgba(4, 12, 24, 1) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    box-shadow: 0px 0px 20px 1px rgb(255, 255, 255), inset 0px 0px 25px 1px rgb(0, 0, 0);
    color: var(--color-text);
    border-radius: 1rem;
}

.pay_form .checkout {
    flex-direction: row;
    display: flex;
    align-items: center;


}

.checkout .checkout_summary {
    background-color: transparent;
    width: 95%;
    margin: 1rem;
    text-align: center;
    border-radius: 1rem;

}

.checkout .checkout_summary select {

    width: 80%;
    text-align: center;
    min-height: 30px;
    font-size: 20px;
    font-weight: bolder;
    background-color: transparent;
    color: white;
    border-width: 3px;
    padding-bottom: 4px;
    border-image:
        linear-gradient(111.3deg, #FF5858 5%, #7FBBFF 90%) 0 0 100% 0;
}


.checkout .country_zip option {

    background-color: var(--color-footer);
    color: white;


}

.checkout .checkout_summary h1 {
    color: white;
    padding-top: 1rem;
    letter-spacing: 3px;
    font-size: 24px;
    font-weight: 900;
    font-family: var(--font-family-data-display);
    margin-bottom: 0px;

}


.checkout .checkout_summary h2 {
    color: white;
}

.checkout .checkout_summary p {
    color: white;
    font-weight: 900;
    letter-spacing: 1px;
    font-size: 24px;
    max-width: fit-content;
    align-self: center;
    margin: auto;
    font-family: var(--font-family-data-display);
    flex-direction: row;
    display: flex;
    text-shadow: 3px 3px 0px rgb(0, 0, 0), 0 0 .1em rgb(0, 0, 0), 0 0 0.3em rgb(255, 255, 255);
}

.checkout .checkout_summary .price {
    font-size: 28px;
    text-decoration: none;
    text-shadow: 3px 3px 0px rgb(0, 0, 0), 0 0 .1em rgb(0, 0, 0), 0 0 0.3em rgb(255, 255, 255);
}

.pay_page .pay_card_input {
    display: flex;
    flex: auto;
    margin: auto;
    flex-direction: column;
    max-width: 400px;
    padding-top: 4rem;

}

.subList_div {
    max-width: 55%;
    margin: auto;

}

.subList_div li {
    list-style-type: none;
    font-style: italic;
    font-size: larger;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    align-items: center;
}

.subList_div .list_border_div {
    margin-top: 5px;
    width: 50px;
    height: 5px;
    background: var(--gradient-separator);
    border-radius: 1rem;



}

.checkout_summary .list_border_div-big {
    align-self: center;

    margin: 0 4px;
    width: 5px;
    height: 30px;
    background: var(--gradient-separator-left);
    border-radius: 1rem;



}


@media screen and (max-width: 1050px) {

    .pay_form .billing_input img {
        width: 325px;
        margin: 2rem;

    }

    .pay_form .checkout {
        display: flex;
        flex-direction: column;

    }

    .pay_page h2 {
        font-size: 35px;
    }


}

@media screen and (max-width: 900px) {


    .pay_form .billing_input img {
        width: 300px;
        margin: 1rem;

    }

    .pay_page h2 {
        font-size: 30px;
    }

    .pay_page p {

        font-size: 18px;
    }

    .checkout .checkout_summary h1 {

        letter-spacing: 1px;
        font-size: 28px;
        font-weight: 900;
        font-family: var(--font-family-data-display);
        margin-bottom: 0px;

    }

    .checkout .checkout_summary p {
        font-size: 24px;
    }



}

@media screen and (max-width: 750px) {
    .pay_form .billing_input img {
        width: 275px;
        align-self: center;

    }

    .pay_page h2 {
        font-size: 28px;
        font-weight: 600;
    }

    .pay_page p {

        font-size: 17px;
    }

    .checkout .checkout_summary h1 {

        font-size: 26px;
        font-weight: 800;

    }

    .pay_form {
        padding: 2rem;
    }

    .pay_form .billing_input .name_info {
        flex-direction: column;
        display: flex;


    }



    .pay_form .city_state {
        flex-direction: column;
        display: flex;

    }

    .pay_form .country_zip {
        flex-direction: column;
        display: flex;

    }


    .pay_form .billing_input label {
        width: auto;
        padding: 0 2rem;

    }

}

@media screen and (max-width: 650px) {
    .pay_page form label {
        font-size: large;



    }

    .pay_form {
        margin: 0 3rem;
        padding: 0;
    }

    .pay_form .billing_input img {
        width: 250px;


    }

    .pay_page h2 {
        font-size: 25px;
        font-weight: 500;
    }

    .pay_page p {

        margin: 0 4rem;

        font-size: 18px;
    }

}

@media screen and (max-width: 525px) {
    .pay_page h2 {
        font-size: 23px;
    }

    .pay_page p {

        font-size: 16px;
    }

    .pay_form .billing_input img {
        width: 200px;


    }

    .pay_form .billing_input label {
        justify-self: center;
        padding: 0 1rem;
        width: auto;
        margin: 5px 0rem;


    }

    .pay_form {
        margin: 0 2rem;
        padding: 0rem;
    }

    .pay_form input {

        max-width: auto;


    }


    .pay_form select {

        max-width: auto;

    }

    .pay_page .pay_card_input {
        align-items: center;

        max-width: 200px;
        padding-top: 2rem;

    }

    .checkout .checkout_summary h1 {

        letter-spacing: 1px;
        font-size: 22px;
        font-weight: 700;
        font-family: var(--font-family-data-display);
        margin-bottom: 0px;

    }

    .checkout .checkout_summary p {
        font-size: 20px;
    }

    .checkout .checkout_summary li {
        font-size: 18px;
    }

    .checkout .checkout_summary select {
        font-size: 19px;

    }

    .pay_page form label {
        font-size: 16px;



    }
}

@media screen and (max-width: 450px) {
    .pay_form .billing_input img {
        width: 175px;


    }

    .pay_form .billing_input label {
        justify-self: center;
        padding: 0 1rem;

        margin: 5px 0rem;


    }

    .pay_form .billing_input input {
        justify-self: center;
        width: 100%;




    }
}