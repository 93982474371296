.iq__account {


    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    margin-bottom: 6rem;
    align-items: center;
    justify-content: center;

}

.iq__account-header h1 {

    margin: 3rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 40px;
    font-family: var(--big-header-font-family);
    letter-spacing: 2px;
    word-spacing: 3px;
    flex-wrap: wrap;
    text-wrap: wrap;
}

.iq__account-div {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    min-width: 85%;
    align-items: center;

    min-height: auto;

    box-shadow: 0px 0px 20px 1px rgb(255, 255, 255), inset 0px 0px 25px 1px rgb(0, 0, 0);
    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 10%, rgba(4, 12, 24, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 10%, rgba(4, 12, 24, 1) 100%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 10%, rgba(4, 12, 24, 1) 100%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 10%, rgba(4, 12, 24, 1) 100%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 10%, rgba(4, 12, 24, 1) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.iq__account-details {
    display: flex;
    flex-direction: row;
    min-width: 75%;

    align-content: center;
    justify-content: space-around;



    border-radius: 1rem;

}

.iq__account-details h3 {
    border-radius: 8px;
    font-size: 28px;
    color: white;
    padding-bottom: 1rem;
    font-family: var(--font-family-data-display);
    letter-spacing: 2px;
    font-weight: 800;
    text-shadow: -1px -1px 5px rgb(0, 0, 0), 0px 0px .4em rgb(0, 0, 0), -1px -1px .1em rgb(255, 255, 255);
}

.border_div {

    height: 5px;
    background: var(--gradient-separator);
    border-radius: 1rem;

}

.iq__account-details h4 {
    color: white;
    font-family: var(--font-family-data-display);
    font-weight: 800;
    letter-spacing: 1px;
    font-size: 28px;
    padding-left: 1rem;
    padding-top: 1rem;
    max-width: fit-content;
    text-shadow: -1px -1px 5px rgb(0, 0, 0), 0px 0px .4em rgb(0, 0, 0), -1px -1px .1em rgb(255, 255, 255);
}

.iq__account-details p {
    font-size: large;
    color: var(--color-text);
    font-weight: 800;
    font-size: x-large;
    padding-left: 1.5rem;
    letter-spacing: 1px;


}

.iq__account-details-container {
    padding: 3rem 2rem;
    border-radius: 1rem;

    text-align: start;
}

.iq__account-div img {

    margin-top: 4rem;
    height: 125px;
    padding: .5rem;
}

.iq__account-details-subscription {
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem;
    border-radius: 1rem;
    text-align: start;
}

.iq__account-details-subscription_button {
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;
}

.iq__account-details-subscription_button p {
    margin-top: .5rem;
    margin-bottom: .5rem;
    color: var(--color-text);
    opacity: 50%;
}

.iq__account-details-subscription_button .change_billing {
    cursor: pointer;
    color: #fff;
}

.iq__account-details-subscription_button button {
    color: #fff;
    font-size: 23px;
    font-weight: 700;
    font-family: var(--font-family-data-display);
    letter-spacing: 1px;
    padding: 1rem 3rem;
    border-radius: 1rem;
    margin: auto;
    border-width: 1px;
    border-style: solid;
    background-color: #ff2f00;

    cursor: pointer;
}

.iq__account-paused_label {
    display: flex;
    flex-wrap: wrap;
    max-width: 70%;
    justify-items: flex-start;
}

p.iq__account-paused_label {

    font-size: 12px;
    opacity: 50%;

}

.iq__account-details-subscription_button button:hover {
    background-color: rgb(248, 70, 30);
    border-width: 3px;


}


@media screen and (max-width: 900px) {

    .iq__account-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;

    }

    .iq__account-details h4 {
        font-size: 24px;

    }

    .iq__account-header h1 {

        font-size: 35px;
        letter-spacing: 1px;
        word-spacing: 2px;

    }
}

@media screen and (max-width: 750px) {


    .iq__account-details h3 {

        font-size: 26px;


    }


}

@media screen and (max-width: 525px) {
    .iq__account-header h1 {

        font-size: 30px;


    }

    .iq__account-details h3 {

        font-size: 24px;


    }

    .iq__account-details h4 {
        font-size: 22px;



    }

    .iq__account-details p {
        font-size: 22px;



    }

    .iq__account-div img {

        margin-top: 2rem;
        height: 100px;
    }


    .iq__account-div {
        display: flex;
        flex-direction: column;
        border-radius: 1rem;
        min-width: 85%;
        align-items: center;
        min-height: auto;
        box-shadow: 0px 0px 12px 1px rgb(255, 255, 255), inset 0px 0px 20px 1px rgb(0, 0, 0);
    }

    .iq__account-details-subscription_button button {

        font-size: 21px;

    }
}

@media screen and (max-width: 450px) {
    .iq__account-header h1 {

        font-size: 26px;


    }

    .iq__account-details h3 {

        font-size: 21px;


    }

    .iq__account-details h4 {
        font-size: 19px;



    }

    .iq__account-details p {
        font-size: 20px;



    }

    .iq__account-div {
        min-width: none;
    }

    .iq__account-div img {

        margin-top: 2rem;
        height: 100px;
    }

    .iq__account-details-subscription_button button {
        padding: 1rem .75rem;
        font-size: 19px;
    }
}

@media screen and (max-width: 400px) {
    .iq__account-details-container {
        padding: 1rem 0rem;


    }

    .iq__account-details h3 {

        font-size: 19px;


    }

    .iq__account-details h4 {
        font-size: 17px;



    }

    .iq__account-details p {
        font-size: 19px;



    }

    .iq__account-details-subscription_button button {
        padding: 1rem .75rem;
        font-size: 19px;
    }

    .iq__account-details-subscription_button p {

        font-size: 14px;
    }

    .iq__account-details-subscription_button b {

        font-size: 14px;
    }


}

@media screen and (max-width: 380px) {
    .iq__account-details-container {
        padding: 1rem .5rem;


    }

    .iq__account-details h3 {

        font-size: 18px;


    }

    .iq__account-details h4 {
        font-size: 16px;



    }

    .iq__account-details p {
        font-size: 17px;



    }

    .iq__account-details-subscription_button button {
        padding: 1rem .75rem;
        font-size: 17px;
    }



    .iq__account-div img {

        margin-top: 1rem;
        height: 85px;
    }
}

@media screen and (max-width: 350px) {
    .iq__account-details-container {
        padding: 1rem .5rem;


    }

    .iq__account-header h1 {

        font-size: 24px;

        font-weight: 500
    }

    .iq__account-details h3 {

        font-size: 17px;


    }

    .iq__account-details h4 {
        font-size: 15px;



    }

    .iq__account-details p {
        font-size: 16px;



    }

    .iq__account-details-subscription_button button {
        padding: 1rem .75rem;
        font-size: 16px;
    }
}

@media screen and (max-width: 325px) {

    .iq__account-div img {

        margin-top: 1rem;
        height: 80px;
    }

    .iq__account-details-container {
        padding: 1rem .5rem;


    }

    .iq__account-header h1 {

        font-size: 22px;
    }

    .iq__account-details h3 {

        font-size: 15px;

        font-weight: 800;
    }

    .iq__account-details h4 {
        font-size: 14px;

        font-weight: 600;

    }

    .iq__account-details p {
        font-size: 14px;

        font-weight: 600;

    }

    .iq__account-details-subscription_button button {
        padding: .80rem .25rem;
        font-size: 14px;
    }
}

@media screen and (max-width: 300px) {

    .iq__account-div img {

        margin-top: 1rem;
        height: 75px;
    }

    .iq__account-details-container {
        padding: 1rem .5rem;


    }

    .iq__account-header h1 {

        font-size: 21px;
    }

    .iq__account-details h3 {

        font-size: 14px;

        font-weight: 700;
    }

    .iq__account-details h4 {
        font-size: 14px;


    }

    .iq__account-details p {
        font-size: 14px;


    }


}

@media screen and (max-width: 275px) {

    .iq__account-div img {

        margin-top: 1rem;
        height: 65px;
    }

    .iq__account-header h1 {

        font-size: 20px;
    }

    .iq__account-details h3 {

        font-size: 13px;

        font-weight: 700;
    }

    .iq__account-details h4 {
        font-size: 13px;


    }
}