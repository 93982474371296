.tos-page {
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    border-radius: 2rem;
    color: white;
}

.tos-intro {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}


.tos-container {
    margin: 4rem 10rem;
    margin-bottom: 10rem;
    background: var(--color-footer);


    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.tos-intro h1 {
    font-family: var(--big-header-font-family);
    color: #fff;
    margin: 1rem 0;
}