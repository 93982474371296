.email_unsub-container{
    display: grid;
    grid-template-columns:1fr;
    grid-template-rows: auto;
    grid-template: "a"
                   "b";
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 600px;
    margin: auto;
    padding: 2rem;
}

.email_unsub-img{
    grid-area: a;
}
.email_unsub-info{
    grid-area: b;
    color: var(--color-text);
    align-self: start;



}

.email_unsub-info h1, p{
    color: #FFFFFF;


}

.email_unsub-info h3{
    color: var(--color-subtext);
    padding-bottom: 1rem;
}

.email_unsub-redeem {

    justify-self: start;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    margin:auto;
    padding: .5rem;
    max-width: 175px;
    border: 1px solid var(--color-text);
    cursor: pointer;
    background-color: #ff4820;
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 700;

}