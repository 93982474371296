.pricing_container {
    display: flex;
    flex-direction: column;

    margin: 0 3rem;
    padding: 3rem 0;
}

.signup-link {
    display: flex;
    align-self: center;
    margin: auto;
    width: 200px;
    font-size: 16px;
    font-family: var(--font-family);
    font-weight: 600;

    color: #FF8A71;

    cursor: pointer;
}

.pricing-header {
    color: #FFFFFF;
    text-align: center;
    font-weight: 800;
    font-style: normal;
    font-size: 34px;
    line-height: 45px;
    text-shadow: rgba(255, 255, 255, 0.25) 0px 0 20px;
}

.pricing-comp_container {
    display: flex;
    flex-direction: row;
}

.pricing_button {
    display: flex;
    align-self: center;


    margin: auto;
    width: 200px;
    padding: 0.75rem;
    font-size: 16px;
    font-family: var(--font-family);
    font-weight: 600;
    background-color: #ff4820;
    color: #fff;
    cursor: pointer;

}

.pricing_link {
    display: flex;
    align-self: center;


    cursor: default;
}

.pricing_gradient__text {
    background: var(--pricing-gradient-text);
    background-clip: text;
    -webkit-background-clop: text;
    -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 950px) {

    .pricing-comp_container {
        display: flex;
        flex-direction: column;
    }

    .pricing_button {
        display: flex;
        align-self: center;
        margin: auto;
        margin-bottom: 1rem;
        padding: 14px 0;
        width: 200px;
        font-size: 18px;
        font-family: var(--font-family);
        font-weight: 600;
        background-color: #ff4820;
        color: #fff;
        cursor: pointer;
    }
}

@media screen and (max-width: 650px) {
    .pricing_container {
        display: flex;
        flex-direction: column;

        margin: 0 0rem;
        padding: 1rem 0;
    }


    .pricing_button {
        padding: 12px 1rem;
        display: flex;
        align-self: center;
        width: 300px;
        font-family: var(--font-family);
        font-weight: 700;
        background-color: #ff4820;
        color: #fff;
        cursor: pointer;
    }

    .pricing-header {
        text-align: center;
        font-weight: 700;
        font-style: normal;
        font-size: 32px;
        line-height: 45px;
        text-shadow: rgba(255, 255, 255, 0.25) 0px 0 20px;
    }
}

@media screen and (max-width: 450px) {
    .pricing_container {
        display: flex;
        flex-direction: column;


    }

    .pricing-comp_container {
        display: flex;
        flex-direction: column;
    }

    .pricing_button {
        display: flex;
        align-self: center;
        width: 200px;
        font-family: var(--font-family);
        font-weight: 700;
        background-color: #ff4820;
        color: #fff;
        cursor: pointer;

        padding: 12px 0rem;
    }

    .pricing-header {
        text-align: center;
        font-weight: 700;
        font-style: normal;
        font-size: 30px;
        line-height: 45px;
        text-shadow: rgba(255, 255, 255, 0.25) 0px 0 20px;
    }

    .pricing-comp p {
        text-align: center;
        font-weight: 500;
        font-style: normal;
        font-size: 20px;

    }





}