.iq__footer {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-footer);
    padding-bottom: 0;
    margin-bottom: 0;
}

.iq__footer-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;

}

.iq__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: bolder;
    font-size: 62px;
    text-align: center;
    letter-spacing: -0.04em;
    padding-top: 1rem;
}

.iq__footer-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 1px solid #FFF;
    text-align: center;
    margin-bottom: 10rem;

    cursor: pointer;
}

.iq__footer-button p {
    box-sizing: border-box;
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 21px;
    color: #FFF;
    word-spacing: 2px;

}

.iq__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    text-align: left;
}

.iq__footer-links div {
    width: 250px;
    margin: 1rem
}

.iq__footer-links_logo {
    display: flex;
    flex-direction: column;

}


.iq__footer-links_logo img {
    width: 250px;
    margin-top: 1rem;
    margin-left: 2rem;
}

.iq__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: #FFF;
}

.iq__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

}

.iq__footer-links_div h4 {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 16px;
    color: #FFF;
    margin-bottom: 1rem;


}


.iq__footer-links_div p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: #FFF;
    margin: 0.5rem 0;
    cursor: pointer;


}

.iq__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;

}

.iq__footer-copyright p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: #FFF;


}

@media screen and (max-width: 850px) {
    .iq__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }


}

@media screen and (max-width: 550px) {
    .iq__footer-heading h1 {
        font-size: 30px;
        line-height: 42px;
    }

    .iq__footer-links div {
        margin: 1rem 1rem;
    }

    .iq__footer-links_logo img {

        margin-top: 0rem;
        margin-left: 0rem;
    }
}

@media screen and (max-width: 450px) {
    .iq__footer-heading h1 {
        font-size: 24px;
        line-height: 38px;
    }

    .iq__footer-links div {
        margin: 1rem 1rem;
    }

    .iq__footer-links_logo img {
        width: 175px;
        margin-top: 0rem;
        margin-left: 0rem;
    }


}