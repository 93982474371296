.iq__predictions {
    flex-direction: column;
    font-family: var(--font-family-data-display);
 color:#FFFFFF;
    margin: auto;

}

.iq__predictions-intro {
    display: flex;
    align-items: center;
    flex-direction: column;

    text-align: center;


}

.iq__predictions-intro h1 {
    color: #fff;
    margin: 2rem 0;
    font-size: 40px;
    font-family: var(--big-header-font-family);
    letter-spacing: 2px;
    word-spacing: 3px;
}

.iq__predictions-intro p {
    color: var(--color-text);
    flex-wrap: wrap;
    max-width: 800px;
    font-size: 18px;

}










.iq__predictions-container {
    margin: 4rem 10rem;

    background: var(--color-footer);
    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));


}




@media screen and (max-width: 1800px) {

    .iq__predictions-intro h1 {
        color: #fff;
        margin: 2rem 0;
        font-size: 45px;
    }

    .iq__predictions-intro p {
        color: var(--color-text);
        flex-wrap: wrap;
        max-width: 800px;
        font-size: 18px;

    }
}

@media screen and (max-width: 1750px) {

    .iq__predictions-intro h1 {
        color: #fff;
        margin: 2rem 0;
        font-size: 45px;
    }

    .iq__predictions-intro p {
        color: var(--color-text);
        flex-wrap: wrap;
        max-width: 800px;
        font-size: 18px;

    }
}

@media screen and (max-width: 1550px) {

    .iq__predictions-intro h1 {
        color: #fff;
        margin: 2rem 0;
        font-size: 45px;
    }

    .iq__predictions-intro p {
        color: var(--color-text);
        flex-wrap: wrap;
        max-width: 800px;
        font-size: 18px;

    }
}


@media screen and (max-width: 1450px) {
    .iq__predictions-intro p {

        padding: 0 1rem;
    }

    .iq__predictions-container {
        margin: 4rem 5rem;
    }




}

@media screen and (max-width: 1250px) {


    .iq__predictions-container {
        margin: 4rem 5rem;
    }




}

@media screen and (max-width: 1050px) {
    .iq__predictions-intro p {
        padding: 0 3rem;
        font-size: 18px;
    }

    .iq__predictions-intro h1 {
        font-size: 38px;

    }

    .iq__predictions-container {
        margin: 3rem 5rem;
    }




}

@media screen and (max-width: 800px) {

    .iq__predictions-container {
        margin: 5rem 3rem;
    }


    .iq__charts-prediction_chart {
        margin: 0;



    }



    .iq__predictions-dashboard {
        display: flex;
        flex-direction: row;
    }

    .iq__predictions-intro p {
        padding: 0 7rem;
        font-size: 14px;
    }

    .iq__predictions-intro h1 {
        font-size: 26px;

    }


}

@media screen and (max-width: 700px) {

    .iq__predictions-container {
        margin: 5rem 3rem;
    }


    .iq__charts-prediction_chart {
        margin: 0;



    }



    .iq__predictions-dashboard {
        display: flex;
        flex-direction: row;
    }

    .iq__predictions-intro p {
        padding: 0 7rem;
        font-size: 13px;
    }

    .iq__predictions-intro h1 {
        font-size: 24px;

    }


}

@media screen and (max-width: 650px) {

    .iq__predictions-intro p {
        font-size: 15px;

    }

    .iq__predictions-intro h1 {
        font-size: 30px;

    }

}

@media screen and (max-width: 525px) {
    .iq__predictions-container {
        margin: 4rem 2.5rem;
    }
}

@media screen and (max-width:450px) {
    .iq__predictions-container {
        margin: 4rem 1.5rem;
    }
}

@media screen and (max-width:350px) {
    .iq__predictions-container {
        margin: 4rem 1rem;
    }
}