.iq__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

}


.iq__brand div img {
    flex: 1;
    margin: 1rem 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 150px;
    filter: grayscale(1);
    opacity: 75%;
}


@media screen and (max-width: 550px) {
    .iq__brand div img {
        flex: 1;
        margin: 1rem 0rem;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 150px;
        filter: grayscale(1);
        opacity: 75%;
    }
}