.preview {
    display: flex;
    flex-direction: column;

    margin: auto;

    font-family: var(--font-family);

}

.preview_top,
.preview_bottom {
    display: flex;
    flex-direction: row;
    padding: 1rem 4rem;
    margin: auto;
    max-width: 90%;

}

.preview_top h1,
.preview_bottom h1 {
    font-family: var(--font-family);
    font-size: 34px;
    font-weight: 800;

    line-height: 45px;
}

.preview_bottom-text {
    justify-content: center;
    color: var(--color-text);
    margin: auto;
    margin-right: 4rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

}

.preview_top-text p,
.preview_bottom-text p {

    max-width: 85%;
}

.preview_top-text {
    justify-content: center;
    color: var(--color-text);
    margin: auto;
    margin-left: 4rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

}

.preview_bottom-img img {
    width: 650px;
    box-shadow: 0 0 20px 2px #48abe0;
    border-radius: 2rem;
}

.preview_top-img img {
    width: 650px;
    border-radius: 2rem;
    box-shadow: 0 0 20px 2px #48abe0;

}

@media screen and (max-width: 1500px) {

    .preview_bottom-img img {
        width: 550px;
        box-shadow: 0 0 20px 2px #48abe0;
        border-radius: 2rem;
    }

    .preview_top-img img {
        width: 550px;
        border-radius: 2rem;
        box-shadow: 0 0 20px 2px #48abe0;

    }

    .preview_top h1,
    .preview_bottom h1 {
        font-family: var(--font-family);
        font-size: 30px;
        font-weight: 800;

        line-height: 45px;
    }

    .preview_top-text p,
    .preview_bottom-text p {

        max-width: 95%;
    }
}

@media screen and (max-width: 1300px) {

    .preview_bottom-img img {
        width: 450px;
        box-shadow: 0 0 20px 2px #48abe0;
        border-radius: 2rem;
    }

    .preview_top-img img {
        width: 450px;
        border-radius: 2rem;
        box-shadow: 0 0 20px 2px #48abe0;

    }

    .preview_top h1,
    .preview_bottom h1 {
        font-family: var(--font-family);
        font-size: 30px;
        font-weight: 800;

        line-height: 45px;
    }

    .preview_bottom-text {

        font-size: 15px;

    }


    .preview_top-text {

        font-size: 15px;


    }
}

@media screen and (max-width: 1200px) {

    .preview {
        display: flex;
        flex-direction: column;

        margin: auto;
        max-width: 100%;
        font-family: var(--font-family);

    }

    .preview_bottom-text {

        margin-right: 2rem;


    }


    .preview_top-text {

        margin-left: 2rem;


    }
}

@media screen and (max-width: 1000px) {


    .preview div {

        align-content: center;
        align-items: center;
        align-self: center;
        margin: auto;
        max-width: 100%;
        font-family: var(--font-family);

    }

    .preview_top,
    .preview_bottom {

        display: grid;
        flex-direction: column;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:
            "a"
            "b"
            "c"
            "d"
        ;
        max-width: 100%;

    }

    .preview_bottom-text {
        grid-area: c;
        padding-bottom: 4rem;
    }

    .preview_bottom-img {
        grid-area: d;

    }



    .preview_top-img {
        grid-area: b;
    }

    .preview_top-text {
        grid-area: a;

    }


}

@media screen and (max-width: 625px) {

    .preview_top h1,
    .preview_bottom h1 {

        font-size: 28px;

    }

    .preview_bottom-img img {
        width: 400px;
        box-shadow: 0 0 20px 2px #48abe0;
        border-radius: 2rem;
    }

    .preview_top-img img {
        width: 400px;
        border-radius: 2rem;
        box-shadow: 0 0 20px 2px #48abe0;

    }
}

@media screen and (max-width: 550px) {
    .preview_top-text {
        padding-bottom: 3rem;

    }

    .preview_top h1,
    .preview_bottom h1 {

        font-size: 28px;

    }

    .preview_bottom-img img {
        width: 350px;
        box-shadow: 0 0 20px 2px #48abe0;
        border-radius: 2rem;
    }

    .preview_top-img img {
        width: 350px;
        border-radius: 2rem;
        box-shadow: 0 0 20px 2px #48abe0;

    }
}

@media screen and (max-width: 500px) {

    .preview_top,
    .preview_bottom {

        padding: 0rem;
    }


    .preview_top h1,
    .preview_bottom h1 {

        font-size: 26px;

    }

    .preview_bottom-img img {
        width: 350px;
        box-shadow: 0 0 20px 2px #48abe0;
        border-radius: 2rem;
    }

    .preview_top-img img {
        width: 350px;
        border-radius: 2rem;
        box-shadow: 0 0 20px 2px #48abe0;

    }
}

@media screen and (max-width: 400px) {

    .preview_bottom-img img {
        width: 300px;
        box-shadow: 0 0 15px 1.5px #48abe0;
        border-radius: 2rem;
    }

    .preview_top-img img {
        width: 300px;
        border-radius: 2rem;
        box-shadow: 0 0 15px 1.5px #48abe0;

    }
}

@media screen and (max-width: 330px) {

    .preview_bottom-img img {
        width: 270px;
        box-shadow: 0 0 10px 1px #48abe0;
        border-radius: 2rem;
    }

    .preview_top-img img {
        width: 270px;
        border-radius: 2rem;
        box-shadow: 0 0 10px 1px #48abe0;

    }


}

@media screen and (max-width: 300px) {

    .preview_bottom-img img {
        width: 220px;
        box-shadow: 0 0 10px 1px #48abe0;
        border-radius: 2rem;
    }

    .preview_top-img img {
        width: 220px;
        border-radius: 2rem;
        box-shadow: 0 0 10px 1px #48abe0;

    }



    .preview_top-text p,
    .preview_bottom-text p {



        font-weight: 500;
        font-size: 14px;

    }


}