.pricing-comp {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    z-index: 1;

}
.pricing_section {
margin: auto;


}

.pricing-comp h1 {
    font-family: var(--font-family-data-display);
    font-size: 36px;
    text-align: center;
    text-shadow: rgba(255, 255, 255, 0.26) 1px 0 15px;
    padding-bottom: 1rem;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: rgb(0, 0, 0);
    font-weight: 800;
}

.pricing-comp p {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    text-shadow: rgba(255, 255, 255, 0.801) 1px 0 15px;
}



.pricing-comp li {
    color: var(--color-text);
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 700;
    text-wrap: wrap;
    padding: .75rem 6rem;
    z-index: 1;

    margin: auto;

}

.pricing-container__feature-bullet {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;


}

.pricing-container__feature-list {
    display: list-item;
    list-style-type: none;


}

.pricing_gradient__text {
    background: var(--pricing-gradient-text);
    background-clip: text;
    -webkit-background-clop: text;

    -webkit-text-fill-color: transparent;
}



@media screen and (max-width: 1600px) {
    .pricing-comp {
        display: flex;
        flex-direction: column;
        padding: 2rem;
        margin: 4rem 1rem;
        z-index: 1;

    }

    .pricing-comp li {
        font-family: var(--font-family);
        font-size: 17px;
        font-weight: 700;
        text-wrap: wrap;
        padding: .75rem 4rem;
        z-index: 1;
    }
}

@media screen and (max-width: 1350px) {
    .pricing-comp {
        display: flex;
        flex-direction: column;
        padding: 2rem;
        margin: 4rem 1rem;
        z-index: 1;

    }

    .pricing-comp li {
        font-family: var(--font-family);
        font-size: 17px;
        font-weight: 700;
        text-wrap: wrap;
        padding: .75rem 2rem;
        z-index: 1;
    }
}

@media screen and (max-width: 1150px) {
    .pricing-comp {
        display: flex;
        flex-direction: column;
        padding: 2rem;
        margin: 4rem 1rem;
        z-index: 1;

    }

    .pricing-comp li {
        font-family: var(--font-family);
        font-size: 17px;
        font-weight: 700;
        text-wrap: wrap;
        padding: .75rem 1rem;
        z-index: 1;
    }
}

@media screen and (max-width: 1150px) {
    .pricing-comp {
        display: flex;
        flex-direction: column;
        padding: 2rem;
        margin: 4rem 1rem;
        z-index: 1;

    }

    .pricing-comp li {
        font-family: var(--font-family);
        font-size: 17px;
        font-weight: 700;
        text-wrap: wrap;
        padding: .75rem 1rem;
        z-index: 1;
    }
}

@media screen and (max-width: 950px) {
    .pricing-comp {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        margin: 1rem 0rem;
        z-index: 1;

    }

    .pricing-comp li {
        font-family: var(--font-family);
        font-size: 17px;
        font-weight: 700;
        text-wrap: wrap;
        padding: .75rem 1rem;
        z-index: 1;
    }

    .pricing-container__feature-list {
        text-align: center;

        width: 400px;
        align-self: center;
        margin: auto;
    }

    .pricing-container__feature-bullet {
        align-self: center;
        margin: auto;

    }

    .pricing_section {
        padding-bottom: 2rem;
    }
}

@media screen and (max-width: 650px) {


    .pricing-comp li {
        font-family: var(--font-family);
        font-size: 16px;
        font-weight: 700;
        text-wrap: wrap;
        padding: .75rem 1rem;
        z-index: 1;
    }

    .pricing-comp {
        display: flex;
        flex-direction: column;
        padding: 2rem;
        margin: 2rem 1rem;
        z-index: 1;

    }

    .pricing-container__feature-list {
        text-align: center;

        width: 400px;
        align-self: center;
        margin: auto;
    }

    .pricing-container__feature-bullet {
        align-self: center;
        margin: auto;

    }

    .pricing-comp h1 {
        font-size: 40px;

    }
}

@media screen and (max-width: 450px) {


    .pricing-comp li {
        font-family: var(--font-family);
        font-size: 16px;
        font-weight: 600;
        text-wrap: wrap;
        padding: .75rem 1rem;
        z-index: 1;
    }

    .pricing-container__feature-list {
        text-align: center;

        width: 300px;
        align-self: center;
        margin: auto;
    }

    .pricing-container__feature-bullet {
        align-self: center;
        margin: auto;

    }

    .pricing-comp h1 {
        font-size: 38px;

    }
}

@media screen and (max-width: 350px) {


    .pricing-comp li {
        font-family: var(--font-family);
        font-size: 15px;
        font-weight: 500;
        text-wrap: wrap;
        padding: .75rem 0rem;
        z-index: 1;
        max-width: 90%;
    }

    .pricing-comp h1 {
        font-size: 36px;

    }

    .pricing-comp p {
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        text-shadow: rgba(255, 255, 255, 0.801) 1px 0 15px;
    }

    .pricing-container__feature-list {
        text-align: center;

        width: 275px;
        align-self: center;
        margin: auto;
    }

    .pricing-container__feature-bullet {
        align-self: center;
        margin: auto;

    }
}