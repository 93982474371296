.iq__predictions-container_dropdown {
    padding-top: 1rem;
    max-width: 550px;
    max-height: 20px;
    width: 100%;
    margin: auto;
    z-index: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: var(--font-family);
    color: var(--color-text);
    background-color: transparent;

}

@mixin styling() {
    font-size: 20px;
    border-color: #ffffff;
    border-style: solid;
    border-width: 1px;
    z-index: 1;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    color: #ffffff;
    box-shadow: 0 .125rem .25rem rgba(243, 0, 0, 0.075) !important;
}

.iq__dd-wrapper {
    display: flex;

    min-height: 38px;
    flex-wrap: wrap;

    .iq__dd-header {

        @include styling();
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        width: 100%;
        padding: 10px 20px;

        &_title--bold {
            font-weight: bold;

        }

        &_title {
            margin: auto;
        }

        &_action {
            font-size: 16px;
            align-self: flex-end;
        }
    }


    .iq__dd-list {

        box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
        padding: 8px;

        width: 100%;
        z-index: 1;
        overflow-y: scroll;
        max-height: 500px;
        padding-right: .5rem;
        scrollbar-width: thin;
        scroll-behavior: smooth;


        li {
            list-style: none;


            &:first-of-type {
                >button {}
            }


            &:last-of-type>button {

                margin-bottom: .5rem;
            }

            button {
                display: flex;
                margin-top: 4px;
                margin-bottom: 0px;

                justify-content: center;

                background-color: var(--color-bg);
                font-size: 19px;
                padding: 10px 10px 7px 10px;
                border: 1px solid #ccc;
                width: 100%;

                font-weight: 700;

                &:hover,
                &:focus {

                    cursor: pointer;
                    font-weight: bold;
                    background-color: #ccc;
                }
            }
        }
    }

}


@media screen and (max-width: 1250px) {

    .iq__predictions-container_dropdown {
        max-width: 450px;
        max-height: 20px;



    }

    .iq__dd-header_title--bold {
        font-size: 19px;
    }
}

@media screen and (max-width: 1150px) {

    .iq__predictions-container_dropdown {
        max-width: 400px;
        max-height: 20px;



    }

    .iq__dd-header_title--bold {
        font-size: 18px;
    }
}

@media screen and (max-width: 1050px) {

    .iq__predictions-container_dropdown {
        max-width: 450px;

    }

    .iq__dd-header_title--bold {
        font-size: 19px;
    }
}



@media screen and (max-width: 800px) {
    .iq__predictions-container_dropdown {
        max-width: 400px;
        max-height: 20px;
        padding-bottom: 2rem;
    }

    .iq__dd-header_title--bold {
        font-size: 18px;
    }
}

@media screen and (max-width: 750px) {


    .iq__dropdown-menu {

        height: auto;
        max-width: 275px;
        font-size: 15px;
    }

    .iq__dd-header_title--bold {
        font-size: 17px;
    }



    .iq__dd-header_action svg {

        height: 22px;
        width: 22px;
    }

}

@media screen and (max-width: 700px) {

    .iq__predictions-container_dropdown {
        max-width: 350px;
        max-height: 20px;
        padding-bottom: 2rem;
    }

    .iq__dd-header_title--bold {
        font-size: 16px;
    }

    .iq__dd-header_action svg {

        height: 19px;
        width: 19px;
    }
}

@media screen and (max-width: 650px) {
    .iq__dd-header_title--bold {
        font-size: 14.5px;
    }

    .iq__dd-list-item span {
        font-size: 16px;
        min-height: 28px;

    }

    .iq__dd-header_action svg {

        height: 16px;
        width: 16px;
    }

    .iq__dd-header_action {

        height: 16px;
        width: 16px;
    }

    .iq__predictions-container_dropdown {
        max-width: 300px;
        max-height: 20px;
        padding-bottom: 2rem;
    }

    .iq__dd-header {
        height: 38px;
    }

    .iq__dropdown-menu {

        height: auto;
        max-width: 250px;
        font-size: 13px;
    }
}

@media screen and (max-width: 600px) {
    .iq__dd-header_title--bold {
        font-size: 13px;
    }

    .iq__dd-list-item span {
        font-size: 14px;
        min-height: 20px;

    }

    .iq__dd-header_action svg {

        height: 16px;
        width: 16px;
    }

    .iq__dd-header_action {

        height: 16px;
        width: 16px;
    }

    .iq__predictions-container_dropdown {
        max-width: 300px;
        max-height: 20px;
        padding-bottom: 2rem;
    }

    .iq__dd-header {
        height: 38px;
    }

    .iq__dropdown-menu {

        height: auto;
        max-width: 250px;
        font-size: 13px;
    }
}

@media screen and (max-width: 450px) {
    .iq__dd-header_title--bold {
        font-size: 11px;
    }

    .iq__dd-list-item span {
        font-size: 11px;
        min-height: 16px;

    }

    .iq__dd-header_action svg {

        height: 14px;
        width: 14px;
    }

    .iq__dd-header_action {

        height: 16px;
        width: 16px;
    }

    .iq__predictions-container_dropdown {
        max-width: 250px;
        max-height: 20px;
        padding-bottom: 3rem;
    }

    .iq__dd-header {
        height: 34px;
    }

    .iq__dropdown-menu {

        height: auto;
        max-width: 250px;
        font-size: 12px;
    }
}

@media screen and (max-width: 400px) {
    .iq__dd-header_title--bold {
        font-size: 11px;
    }

    .iq__dd-list-item span {
        font-size: 12px;
        min-height: 16px;

    }

    .iq__dd-header_action svg {

        height: 15px;
        width: 15px;
    }

    .iq__dd-header_action {

        height: 15px;
        width: 15px;
    }

    .iq__predictions-container_dropdown {
        max-width: 250px;
        max-height: 20px;
        padding-bottom: 3rem;
    }

    .iq__dd-header {
        height: 32px;
    }


}

@media screen and (max-width: 350px) {
    .iq__dd-header_title--bold {
        font-size: 11px;
    }

    .iq__dd-list-item span {
        font-size: 11px;
        min-height: 14px;

    }

    .iq__dd-header_action svg {

        height: 12px;
        width: 12px;
    }


    .iq__predictions-container_dropdown {
        max-width: 250px;
        max-height: 20px;
        padding-bottom: 4rem;
    }

    .iq__dd-header {
        height: 30px;
    }


}

@media screen and (max-width: 325px) {
    .iq__dd-header_title--bold {
        font-size: 10px;
    }

    .iq__dd-list-item span {
        font-size: 9px;
        min-height: 12px;

    }

    .iq__dd-header_action svg {

        height: 12px;
        width: 12px;
    }


    .iq__predictions-container_dropdown {
        max-width: 250px;
        max-height: 20px;
        padding-bottom: 4rem;
    }

    .iq__dd-header {
        height: 30px;
    }


}