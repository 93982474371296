.legend_ul {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.custom_XAxis-Label_PM g svg {
    font-size: 18px;
    transform: translateX(0);
}

.custom_XAxis-Label_LA-TD g svg {
    font-size: 18px;
}

.legend_ul li {
    position: relative;
    margin: auto;
}

.AD_legend {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    justify-items: center;
    align-items: center;
    align-self: center;

}


.AD_legend div {
    font-size: 20px;

}

.custom_XAxis-Label_LA text,
.custom_XAxis-Label_LA svg {
    font-size: 18px;
    transform: translateX(-1rem);
}

.custom_XAxis-Label_LA-TD {
    font-size: 18px;
}

.custom_XAxis-Label_LA-TD g svg {
    transform: translateX(-.25rem);
}

g .bar_icon {

    font-size: 45px;
}

.strikes_LA_bar-value {
    font-size: 37px;
    stroke-width: 1.25;
}

@media screen and (max-width: 1550px) {
    .AD_legend div {
        font-size: 19px;

    }

}

@media screen and (max-width: 1450px) {

    .AD_legend div {
        font-size: 18px;

    }



    .recharts-cartesian-axis-ticks {
        font-size: 16px;
    }

    g .bar_icon {

        font-size: 40px;
    }
}

@media screen and (max-width: 1350px) {

    .custom_XAxis-Label_LA text {
        font-size: 15px;

    }

    .custom_XAxis-Label_LA-TD text {
        font-size: 15px;
    }

    .custom_XAxis-Label_LA-TD g svg {
        transform: translateX(.25rem);
    }



    .custom_XAxis-Label_LA-aft text {
        font-size: 19px;

    }

    .strikes_LA_bar-value {
        font-size: 32px;
    }



    .recharts-cartesian-axis-ticks {
        font-size: 15px;
    }


    .AD_legend div {

        padding-left: 2rem;

    }
}

@media screen and (max-width: 1250px) {
    .strikes_LA_bar-value {
        font-size: 25px;
    }

    .strikes_LA_bar-unit {
        font-size: 17px;

    }

    .AD_legend {
        justify-content: center;


    }

    .AD_legend div {
        font-size: 16px;
        padding-left: 1rem;
    }


    .custom_XAxis-Label {

        font-size: 15px;
    }


}

@media screen and (max-width: 1150px) {
    .strikes_LA_bar-value {
        font-size: 30px;
    }

    .strikes_LA_bar-unit {
        font-size: 20px;

    }

    .AD_legend div {
        font-size: 20px;

    }


    .custom_XAxis-Label_LA {
        font-size: 17px;
    }

    .custom_XAxis-Label_LA-TD {
        font-size: 17px;
    }

    .custom_XAxis-Label_LA-TD g svg {
        font-size: 18px;
    }

    .custom_XAxis-Label_LA-aft text {
        font-size: 20px;

    }

    .recharts-cartesian-axis-ticks {
        font-size: 16px;
    }

    g .bar_icon {

        font-size: 45px;
    }
}

@media screen and (max-width: 900px) {



    .AD_legend div {
        font-size: 19px;

    }


}

@media screen and (max-width: 850px) {


    .AD_legend div {
        font-size: 18px;

    }



}

@media screen and (max-width: 800px) {

    .strikes_LA_bar-value {
        font-size: 27px;
    }

    .strikes_LA_bar-unit {
        font-size: 18px;

    }

    .custom_XAxis-Label_PM text {

        font-size: 16px;
    }



    .recharts-cartesian-axis-ticks {
        font-size: 15px;
    }

    g.custom_XAxis-Label_LA-aft text {
        font-size: 18px;
        transform: translateX(-.5rem);
    }

    g .bar_icon {

        font-size: 40px;
    }

    .AD_legend div {
        font-size: 15px;

    }

    .custom_XAxis-Label_LA text {
        font-size: 16px;
    }

    .custom_XAxis-Label_LA-TD text {
        font-size: 16px;
    }
}

@media screen and (max-width: 750px) {


    .AD_legend div {
        font-size: 15px;

    }

    .recharts-cartesian-axis-ticks {
        font-size: 15px;
    }



    .strikes_LA_bar-value {
        font-size: 27px;
        stroke-width: 1;
    }

    .strikes_LA_bar-unit {
        font-size: 17px;

    }



}

@media screen and (max-width: 700px) {


    .AD_legend div {
        font-size: 14px;

    }

    .recharts-cartesian-axis-ticks tspan {
        font-size: 14px;
    }

    .custom_XAxis-Label_LA text {
        font-size: 15px;
    }



    .custom_XAxis-Label_LA {
        font-size: 18px;
    }

    .custom_XAxis-Label_LA text {
        font-size: 15px;

    }



    .custom_XAxis-Label_LA-TD g svg {
        font-size: 18px;
    }

    .custom_XAxis-Label_LA-TD text {
        font-size: 15px;
    }

    .custom_XAxis-Label_PM text {
        font-size: 15px;

    }

    .strikes_LA_bar-value {
        font-size: 26px;
    }

    .strikes_LA_bar-unit {
        font-size: 16px;

    }

    g.custom_XAxis-Label_LA-aft text {
        font-size: 17px;
    }

    g .bar_icon {

        font-size: 35px;
    }

}

@media screen and (max-width: 650px) {


    .AD_legend div {
        font-size: 14px;

    }

    .recharts-cartesian-axis-ticks {
        font-size: 14px;
    }

    .custom_XAxis-Label_LA {
        font-size: 18px;
    }

    .custom_XAxis-Label_LA text {
        font-size: 15px;

    }

    .custom_XAxis-Label_LA-TD g svg {
        font-size: 18px;
    }

    .custom_XAxis-Label_LA-TD {
        font-size: 14px;
    }

    .strikes_LA_bar-value {
        font-size: 26px;
    }

    .strikes_LA_bar-unit {
        font-size: 16px;

    }

    g.custom_XAxis-Label_LA-aft text {
        font-size: 17px;
    }

    g .bar_icon {

        font-size: 32px;
    }





    .custom_XAxis-Label_LA-TD text {
        font-size: 15px;
    }



}

@media screen and (max-width: 580px) {



    .recharts-cartesian-axis-ticks tspan {
        font-size: 13px;
    }


    .custom_XAxis-Label_PM {
        font-size: 14px;

    }

    .custom_XAxis-Label_PM text {
        font-size: 14px;
    }

    .custom_XAxis-Label_PM g svg {

        transform: translateX(10px);
    }



    .strikes_LA_bar-value {
        font-size: 24px;
    }

    .strikes_LA_bar-unit {
        font-size: 14px;

    }

    g.custom_XAxis-Label_LA-aft text {
        font-size: 16px;
    }

    g .bar_icon {

        font-size: 30px;
    }

    .custom_XAxis-Label_LA-TD g svg {

        transform: translateX(10px);
    }

    .custom_XAxis-Label_LA-TD text {
        font-size: 14px;
    }
}

@media screen and (max-width: 550px) {








    .strikes_LA_bar-value {
        font-size: 22px;
        stroke-width: .75;
    }

    .strikes_LA_bar-unit {
        font-size: 14px;

    }

    g.custom_XAxis-Label_LA-aft text {
        font-size: 16px;
        transform: translateX(-.7rem);
    }

    g.custom_XAxis-Label_LA-aft g svg {
        font-size: 16px;
        transform: translateX(.4rem);
    }

    g .bar_icon {

        font-size: 30px;
    }



    .custom_XAxis-Label_PM text {
        font-size: 12px;
    }

    .custom_XAxis-Label_PM g svg {
        font-size: 16px;
        transform: translateX(1.25rem);
    }

    .custom_XAxis-Label_LA text {
        font-size: 12px;

    }

    .custom_XAxis-Label_LA-TD text {
        font-size: 12px;
    }

    .custom_XAxis-Label_LA-TD g svg {
        font-size: 18px;
        transform: translateX(1rem);
    }

    .custom_XAxis-Label_LA g svg {
        font-size: 16px;
        align-self: flex-start;


    }
}

@media screen and (max-width: 500px) {
    .strikes_LA_bar-value {
        font-size: 20px;
        stroke-width: .65;
    }

    .strikes_LA_bar-unit {
        font-size: 12px;

    }

    .AD_legend div {
        font-size: 13px;
    }

    g.custom_XAxis-Label_LA-aft text {
        font-size: 15px;
    }

    g.custom_XAxis-Label_LA-aft g svg {
        font-size: 15px;

    }

    .recharts-cartesian-axis-ticks tspan {
        font-size: 12px;
    }
}


@media screen and (max-width: 480px) {


    .custom_XAxis-Label_PM text {
        font-size: 11px;
    }

    .custom_XAxis-Label_PM g svg {
        font-size: 14px;
        transform: translateX(1.75rem)translateY(3px);
    }

    .custom_XAxis-Label_LA-TD text {
        font-size: 12px;

    }

    .custom_XAxis-Label_LA-TD g svg {
        font-size: 14px;
        transform: translateX(1.45rem) translateY(3px);



    }

    .custom_XAxis-Label_LA text {
        font-size: 12px;

    }

    .custom_XAxis-Label_LA g svg {
        font-size: 14px;
        transform: translateX(-.9rem) translateY(3px);
    }



    .strikes_LA_bar-value {
        font-size: 18px;
    }

    .strikes_LA_bar-unit {
        font-size: 12px;

    }


    .AD_legend div {
        font-size: 12px;

    }

    g .bar_icon {

        font-size: 24px;
    }



    g.custom_XAxis-Label_LA-aft text {
        font-size: 14px;
    }

    g.custom_XAxis-Label_LA-aft g svg {
        transform: translateX(.8rem);
    }


}



@media screen and (max-width: 400px) {
    .AD_legend div {
        font-size: 9px;
        transform: translateY(-2px);
    }

    .recharts-cartesian-axis-ticks tspan {
        font-size: 11px;

    }

    g.custom_XAxis-Label_LA-aft text {
        font-size: 12px;
        transform: translateY(-6px);
    }

    g.custom_XAxis-Label_LA-aft g svg {
        transform: translateX(2.5rem)scale(.75);

    }

    .custom_XAxis-Label_LA-TD text {
        font-size: 9px;

    }

    .custom_XAxis-Label_LA-TD g svg {
        font-size: 11px;
        transform: translateX(2.4rem)translateY(6px);

    }

    .custom_XAxis-Label_LA text {
        font-size: 9px;

    }

    .strikes_LA_bar-value {
        font-size: 14px;
        stroke-width: .5;
    }

    .strikes_LA_bar-unit {
        font-size: 9px;
        stroke-width: .25px;

    }

    g .bar_icon {
        font-size: 16px;
    }

    .custom_XAxis-Label_PM text {
        font-size: 9px;
    }

    .custom_XAxis-Label_PM g svg {
        font-size: 9px;
        transform: translateX(2.7rem)translateY(5px);

    }
}

@media screen and (max-width: 350px) {
    .AD_legend div {
        font-size: 8px;
        transform: translateY(-2px) translateX(4px);
    }

    .recharts-polar-radius-axis-tick {
        font-size: 11px;
    }

    g.parts_icon g svg {
        transform: scale(.8)translateX(2.1rem)translateY(15px);
    }

    .recharts-cartesian-axis-ticks tspan {
        font-size: 9px;

    }

    g.custom_XAxis-Label_LA-aft text {
        font-size: 11px;
        transform: translateY(-6px);
    }

    g.custom_XAxis-Label_LA-aft g svg {
        transform: translateX(2.5rem)scale(.75);

    }

    .custom_XAxis-Label_LA-TD text {
        font-size: 8px;

    }

    .custom_XAxis-Label_LA-TD g svg {
        font-size: 10px;
        transform: translateX(2.7rem)translateY(6px);

    }

    .custom_XAxis-Label_LA text {
        font-size: 8px;

    }

    .custom_XAxis-Label_LA svg {
        font-size: 8px;
    }

    .custom_XAxis-Label_LA g svg {
        font-size: 11px;
        transform: translateX(-.7rem) translateY(6px);
    }

    .strikes_LA_bar-value {
        font-size: 12px;
        stroke-width: .5;
    }

    .strikes_LA_bar-unit {
        font-size: 8px;
        stroke-width: .25px;

    }

    g .bar_icon {
        font-size: 16px;
    }

    .custom_XAxis-Label_PM text {
        font-size: 8px;
    }

    .custom_XAxis-Label_PM g svg {
        font-size: 9px;
        transform: translateX(3rem)translateY(5px);

    }
}