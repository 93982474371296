.iq__fighter-stat_comparison {
    display: flex;

    justify-content: center;
    color: red;
    margin: 0rem 2rem;
    margin-bottom: 2rem;
    border-radius: 1rem;


    font-family: var(--big-header-font-family);


}

.iq__fighter-stat_comparison .recharts-responsive-container {
    box-shadow:
        inset 0 -1em 3em rgba(255, 255, 255, 0.1),
        0 0 0 1.5px rgb(255, 255, 255),
        0em 0em 1em rgba(255, 255, 255, 0.735);

    border-radius: 1rem;


}

.iq__fighter-stat_comparison h1 {
    margin-bottom: 4rem;
    margin-top: 2rem;
    color: rgb(255, 255, 255);

}

.iq__fighter-stat_comparison h2 {
    margin-bottom: 1rem;
    margin-top: 1rem;
    text-decoration: underline;
    color: rgb(255, 255, 255);

}






.iq__fighter-stat_comparison .containers {
    display: flex;
    flex-direction: column;

    background-color: transparent;


    margin-top: 1rem;
    margin-bottom: 2rem;
    border-radius: 1rem;


}



.iq__fighter-stat_comparison .containers .row {

    display: flex;
    flex-direction: row;

    border-radius: 1rem;

    justify-content: center;


}

.iq__fighter-stat_comparison .containers .row .row_chart {
    height: inherit;
    width: inherit;
    max-width: 700px;
    max-height: 600px;
    height: 600px;
    width: 700px;
    margin: 2rem 1rem;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    align-self: center;
    justify-self: center;
}




.iq__fighter-stat_comparison .scatter_container {
    display: flex;
    justify-content: center;
}



.iq__fighter-stat_comparison .scatter_container .scatter_align {

    width: fit-content;
    justify-content: center;
    background-color: #0a1c38;
    box-shadow:
        inset 0 -1em 3em rgba(255, 255, 255, 0.1),
        0 0 0 1.5px rgb(255, 255, 255),
        0em 0em 1em rgba(255, 255, 255, 0.735);

    margin-top: 2rem;
    margin-bottom: 2rem;
    border-radius: 1rem;
}

.iq__predictions-container_holder h3 {
    color: #fff;
}

.iq__charts-prediction_chart p {
    display: flex;
    justify-content: center;
    color: var(--color-text);

}



.iq__predictions-container_holder {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template:

        "a b c"
        "d d d";
    text-align: center;
    align-items: center;
    margin: auto;
    font-family: var(--font-family);

    border-radius: 2rem;

    position: relative;
    justify-content: center;
    z-index: 0;
}


div.iq__predictions-container_holder .iq__predictions-dashboard_red {
    grid-area: a;
}

div.iq__predictions-container_holder .iq__predictions-dashboard_blue {
    grid-area: c;
}

div.iq__predictions-container_holder .iq__prediction-display {
    display: grid;
    grid-area: d;


}

.iq__charts-prediction-dashboard {
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 3rem;
    flex-direction: column;


}

.iq__predictions-dashboard_red h3 {
    font-family: var(--big-header-font-family);
    font-size: 45px;
    text-decoration: underline;
    text-decoration-color: #FF5858;
    color: white;
    text-decoration-thickness: 3.5px;
    text-shadow: 1.5px 2px 1px #ff0000;

    -webkit-text-stroke: #000000b0 2px;
}

.iq__predictions-dashboard_blue h3 {

    font-family: var(--big-header-font-family);
    font-size: 45px;
    text-decoration: underline;
    text-decoration-color: #4c9cf8;
    text-decoration-thickness: 3.5px;
    text-shadow: 1.5px 2px 1px #5aa4f8;
    -webkit-text-stroke: #000000c2 2px;
}

.iq__fighter-stats h4 {
    font-size: 22px;
    padding-top: 1rem;
    color: white;
    font-weight: 900;

}

.iq__fighter-stats p {
    font-size: 20px;
    color: var(--color-text);
    font-weight: 700;
}

.iq__charts-prediction_header {
    max-width: 50%;

    margin: auto;


}

.iq__charts-prediction_header p {
    display: flex;

    justify-content: center;

    color: var(--color-text);
    font-size: 20px;

}

.iq__charts-prediction_header h2 {
    color: #fff;
    font-size: 60px;
    font-family: var(--big-header-font-family);

}

div.iq__charts-prediction_chart {
    padding-top: 2rem;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    grid-area: b;

}



.pie_div {
    width: 800px;
    height: 800px;
    z-index: 0;
    margin: -7rem 0rem;
    overflow: visible;
}

.iq__predictions-container_dropdown {
    z-index: 1;
}

.tab_one,
.tab_two,
.tab_three,
.tab_four {
    font-size: 23px;
    padding: 10px 40px;

}



@media screen and (max-width: 1800px) {
    .iq__fighter-stat_comparison .containers .row .row_chart {

        max-width: 650px;
        max-height: 600px;
        height: 600px;
        width: 650px;
        margin: 2rem 1rem;

    }

    .iq__charts-prediction_header h2 {
        font-size: 56px;

    }
}

@media screen and (max-width: 1750px) {
    .iq__charts-prediction_header h2 {
        font-size: 54px;

    }
}

@media screen and (max-width: 1700px) {
    .iq__fighter-stat_comparison .containers .row .row_chart {
        max-width: 625px;
        max-height: 550px;
        height: 550px;
        width: 625px;
        margin: 2rem 1rem;

    }
}

@media screen and (max-width: 1650px) {
    .iq__fighter-stat_comparison .containers .row .row_chart {
        max-width: 600px;
        max-height: 500px;
        height: 500px;
        width: 600px;
        margin: 2rem 1rem;

    }
}

@media screen and (max-width: 1550px) {
    .iq__fighter-stat_comparison .containers .row .row_chart {
        max-width: 575px;
        max-height: 475px;
        height: 475px;
        width: 575px;
        margin: 2rem 1rem;

    }

    .iq__charts-prediction_header {
        max-width: 60%;

        margin: auto;


    }

    .iq__charts-prediction_header h2 {
        color: #fff;


    }

    .iq__charts-prediction_header p {

        font-size: 18px;

    }

    .iq__predictions-dashboard_blue h3,
    .iq__predictions-dashboard_red h3 {
        font-size: 44px;
    }

    .tab_one,
    .tab_two,
    .tab_three,
    .tab_four {
        font-size: 22px;
    }

    .pie_div {
        width: 800px;
        height: 800px;
        margin: -7rem -8rem;
    }

}

@media screen and (max-width: 1500px) {
    .iq__fighter-stat_comparison .containers .row .row_chart {
        max-width: 550px;
        max-height: 500px;
        height: 500px;
        width: 550px;
        margin: 2rem 1rem;

    }

}

@media screen and (max-width: 1450px) {


    .iq__predictions-container_holder {

        margin: auto;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        row-gap: 0px;
        column-gap: 0px;
        grid-template-areas:
            "a b c"
            "d d d";

    }

    .iq__fighter-stat_comparison .containers .row .row_chart {
        max-width: 550px;
        max-height: 500px;
        height: 500px;
        width: 550px;
        margin: 2rem 1rem;

    }

}

@media screen and (max-width: 1350px) {
    .iq__fighter-stat_comparison .containers .row .row_chart {
        max-width: 525px;
        max-height: 475px;
        height: 475px;
        width: 525px;
        margin: 2rem 1rem;

    }
}

@media screen and (max-width: 1250px) {
    .iq__fighter-stat_comparison .containers .row .row_chart {
        max-width: 475px;
        max-height: 425px;
        height: 425px;
        width: 475px;
        margin: 1rem 1rem;

    }

    .iq__charts-prediction_header h2 {
        font-size: 50px;
    }

    .pie_div {
        width: 750px;
        height: 750px;
    }

    .iq__predictions-dashboard_red h3 {
        font-size: 43px;
    }

    .iq__predictions-dashboard_red p {
        font-size: 18px;
    }

    .iq__predictions-dashboard_blue h3 {
        font-size: 43px;
    }

    .iq__predictions-dashboard_blue p {
        font-size: 18px;
    }
}

@media screen and (max-width: 1150px) {
    .iq__fighter-stat_comparison .containers .row .row_chart {
        max-width: 650px;
        max-height: 550px;
        height: 550px;
        width: 650px;
        margin: 1rem 1rem;

    }

    .iq__fighter-stat_comparison .containers .row {
        flex-direction: column;

    }

    .tab_one,
    .tab_two,
    .tab_three,
    .tab_four {
        font-size: 21px;
    }


    .iq__charts-prediction_header {
        max-width: 50%;

        margin: auto;


    }

    .iq__charts-prediction_header p {
        color: var(--color-text);
        font-size: 17px;

        flex-wrap: wrap;
        margin: auto;
        max-width: 55%;


    }

    .iq__charts-prediction_header h2 {
        color: #fff;
        font-size: 55px;
        font-family: var(--big-header-font-family);

    }

    .iq__predictions-dashboard_red h3 {
        font-size: 34px;
    }



    .iq__predictions-dashboard_blue h3 {
        font-size: 34px;
    }


    .iq__predictions-dashboard_blue h4 {
        font-size: 18px;
    }

    .iq__predictions-dashboard_red h4 {
        font-size: 18px;
    }
}

@media screen and (max-width: 1050px) {




    .pie_div {
        width: 800px;
        height: 800px;
    }

    .iq__predictions-container_holder {
        grid-template-columns: 1fr 1fr 1fr 1fr;

        grid-template-areas:
            "b b b b"
            "a a c c"
            "d d d d";
    }

    .iq__prediction-display {
        padding-top: 3rem;
    }

    .iq__charts-prediction_header {
        max-width: 70%;

        margin: auto;


    }

    .iq__charts-prediction_header p {
        color: var(--color-text);
        font-size: 17px;

        flex-wrap: wrap;
        margin: auto;
        max-width: 50%;


    }

    .iq__charts-prediction_header h2 {
        color: #fff;
        font-size: 55px;
        font-family: var(--big-header-font-family);

    }

    .iq__fighter-stats h4 {
        font-size: 22px;
        padding-top: 1rem;
        color: white;
        font-weight: 700;

    }

    .iq__fighter-stats p {
        font-size: 20px;

    }


}

@media screen and (max-width: 1000px) {

    div.iq__charts-prediction_chart .dropdown {

        z-index: 1;
    }




}

@media screen and (max-width: 950px) {


    .iq__predictions-dashboard_red h3 {
        font-size: 39px;
    }



    .iq__predictions-dashboard_blue h3 {
        font-size: 39px;
    }
}



@media screen and (max-width: 900px) {



    .iq__fighter-stat_comparison .containers .row .row_chart {
        max-width: 600px;
        max-height: 500px;
        height: 500px;
        width: 600px;
        margin: 1rem 1rem;

    }



    .tab_one,
    .tab_two,
    .tab_three,
    .tab_four {

        padding: 5px 30px;
        font-size: 20px;
    }

    .iq__charts-prediction_header {
        max-width: 80%;

        margin: auto;


    }

    .iq__charts-prediction_header p {
        color: var(--color-text);
        font-size: 16px;

        flex-wrap: wrap;
        margin: auto;
        max-width: 50%;


    }

    .iq__charts-prediction_header h2 {
        color: #fff;
        font-size: 45px;
        font-family: var(--big-header-font-family);

    }

    .iq__predictions-dashboard_red h3 {
        font-size: 38px;
    }



    .iq__predictions-dashboard_blue h3 {
        font-size: 38px;
    }
}

@media screen and (max-width: 850px) {


    .iq__predictions-dashboard_red h3 {
        font-size: 35px;

        text-decoration-thickness: 1.5px;
        text-shadow: 1.5px 1px 2px #ff0000;

        -webkit-text-stroke: #000000b0 1.5px;
    }



    .iq__predictions-dashboard_blue h3 {
        font-size: 35px;
        text-decoration-thickness: 1.5px;
        text-shadow: 1.5px 1px 2px #5aa4f8;
        -webkit-text-stroke: #000000c2 1.5px;
    }
}

@media screen and (max-width: 800px) {

    .pie_div {
        width: 700px;
        height: 700px;


    }

    .iq__fighter-stat_comparison .containers .row .row_chart {
        max-width: 500px;
        max-height: 400px;
        height: 400px;
        width: 500px;
        margin: 1rem 1rem;

    }

    .tab_one,
    .tab_two,
    .tab_three,
    .tab_four {
        font-size: 17px;
    }

    .iq__charts-prediction_header {
        max-width: 55%;

        margin: auto;


    }

    .iq__charts-prediction_header h2 {
        font-size: 38px;


    }

    .iq__charts-prediction_header p {
        color: var(--color-text);
        font-size: 15px;

        flex-wrap: wrap;
        margin: auto;
        max-width: 50%;


    }

    .iq__predictions-dashboard_red h3 {
        font-size: 34px;
    }



    .iq__predictions-dashboard_red p {
        font-size: 17px;
    }

    .iq__predictions-dashboard_blue h3 {
        font-size: 34px;
    }

    .iq__predictions-dashboard_blue p {
        font-size: 17px;
    }

    .iq__predictions-dashboard_blue h4 {
        font-size: 18px;
        padding-top: .3rem;
    }

    .iq__predictions-dashboard_red h4 {
        font-size: 18px;
        padding-top: .3rem;
    }
}

@media screen and (max-width: 750px) {
    .pie_div {
        width: 650px;
        height: 650px;


    }

    .iq__predictions-dashboard_red h3,
    .iq__predictions-dashboard_blue h3 {
        font-size: 30px;
    }







    .iq__predictions-dashboard_red h4,
    .iq__predictions-dashboard_blue h4 {
        font-size: 16px;
        padding-top: .5rem;
    }

    .iq__charts-prediction_header h2 {
        font-size: 35px;
    }

    .iq__charts-prediction_header p {
        font-size: 14px;
    }

    .iq__predictions-container_holder {


        grid-template-areas:
            "b b b"
            "a b c"
            "d d d";
    }
}

@media screen and (max-width: 700px) {
    .iq__predictions-dashboard_red h3 {
        font-size: 30px;
    }

    .iq__predictions-dashboard_blue h3 {
        font-size: 30px;
    }


    .iq__predictions-dashboard_red p {
        font-size: 16px;
    }

    .iq__predictions-dashboard_blue p {
        font-size: 16px;

    }

    .iq__predictions-dashboard_blue h4 {
        font-size: 16px;

    }

    .iq__predictions-dashboard_red h4 {
        font-size: 16px;

    }


    .tab_one,
    .tab_two,
    .tab_three,
    .tab_four {
        padding: 3px 12px;
        font-size: 16px;
    }

    .chart_section-header {
        font-size: 30px;
    }

    .iq__charts-prediction_header h2 {
        font-size: 30px;
    }

    .iq__charts-prediction_header p {
        font-size: 13px;
    }
}

@media screen and (max-width: 650px) {

    .pie_div {
        width: 600px;
        height: 600px;


    }





    .iq__charts-prediction_header h2 {
        font-size: 29px;

    }

    .iq__charts-prediction_header p {
        font-size: 14px;
    }

    .chart_section-header {
        font-size: 28px;
    }
}

@media screen and (max-width: 620px) {
    .iq__fighter-stat_comparison .containers .row .row_chart {
        max-width: 450px;
        max-height: 350px;
        height: 350px;
        width: 450px;
        margin: 1rem 1rem;

    }

}

@media screen and (max-width: 600px) {
    .pie_div {
        width: 500px;
        height: 500px;

    }

    .iq__predictions-dashboard_blue h3,
    .iq__predictions-dashboard_red h3 {
        font-size: 28px;
        padding-top: 1rem;
    }

    .iq__charts-prediction_header h2 {
        font-size: 32px;
    }

    .iq__charts-prediction_header p {
        font-size: 13px;
    }

    .iq__charts-prediction-dashboard {
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
        align-items: center;
        padding-top: 3rem;
        flex-direction: column;
        margin: 0 1rem;

    }

    .tab_one,
    .tab_two,
    .tab_three,
    .tab_four {
        padding: 5px 15px;
        font-size: 15px;
    }
}



@media screen and (max-width: 580px) {
    .iq__fighter-stat_comparison .containers .row .row_chart {
        max-width: 430px;
        max-height: 330px;
        height: 330px;
        width: 430px;
        margin: 1rem 1rem;

    }

    .chart_section-header {
        font-size: 24px;
    }

}

@media screen and (max-width: 550px) {
    .iq__fighter-stat_comparison .containers .row .row_chart {
        max-width: 400px;
        max-height: 300px;
        height: 300px;
        width: 400px;


    }

    .iq__charts-prediction_header {
        max-width: 75%;

    }

    .chart_section-header {
        font-size: 22px;
    }


}

@media screen and (max-width: 500px) {
    .iq__fighter-stat_comparison .containers .row .row_chart {
        max-width: 375px;
        max-height: 275px;
        height: 275px;
        width: 375px;


    }


    .pie_div {
        width: 475px;
        height: 475px;

    }

}

@media screen and (max-width: 480px) {
    .iq__fighter-stat_comparison .containers .row .row_chart {
        max-width: 350px;
        max-height: 250px;
        height: 250px;
        width: 350px;
        text-wrap: wrap;

    }

    .iq__charts-prediction_header {
        max-width: 75%;

        margin: auto;
    }

    .iq__charts-prediction_header h2 {
        font-size: 28px;
    }

    .iq__charts-prediction_header p {
        font-size: 11px;
    }

    .pie_div {
        width: 450px;
        height: 450px;

    }

    .tab_one,
    .tab_two,
    .tab_three,
    .tab_four {
        padding: 3px 12px;
        font-size: 13px;
    }

    .iq__predictions-dashboard_blue h3,
    .iq__predictions-dashboard_red h3 {
        font-size: 26px;
        padding-top: 1rem;
    }

}

@media screen and (max-width: 450px) {
    .iq__fighter-stat_comparison .containers .row .row_chart {
        max-width: 350px;
        max-height: 275px;
        height: 330px;
        width: 350px;


    }


    .pie_div {
        width: 425px;
        height: 425px;
    }
}



@media screen and (max-width: 425px) {

    .iq__fighter-stat_comparison .containers .row .row_chart {


        max-width: 350px;
        max-height: 275px;
        height: 330px;
        width: 350px;
    }

    .iq__charts-prediction_header h2 {
        font-size: 28px;
    }

    .pie_div {
        width: 400px;
        height: 400px;
    }

    .tab_one,
    .tab_two,
    .tab_three,
    .tab_four {
        padding: 3px 9px;
        font-size: 12px;
    }

    .iq__predictions-dashboard_blue h3,
    .iq__predictions-dashboard_red h3 {
        font-size: 22px;
        padding-top: 3rem;
    }

    .iq__fighter-stats p {
        font-size: 13px;
    }

    .iq__fighter-stats h4 {
        font-size: 13px;
    }
}


@media screen and (max-width: 400px) {
    .iq__fighter-stat_comparison .containers .row .row_chart {
        max-width: 325px;
        max-height: 250px;
        height: 300px;
        width: 320px;

    }



    .tab_one,
    .tab_two,
    .tab_three,
    .tab_four {
        padding: 2px 5px;
        font-size: 12px;
    }

    .iq__predictions-dashboard_blue h3,
    .iq__predictions-dashboard_red h3 {
        font-size: 22px;

    }

    .pie_div {
        width: 375px;
        height: 375px;

    }
}

@media screen and (max-width: 375px) {
    .pie_div {
        width: 350px;
        height: 350px;

    }

    .iq__fighter-stat_comparison .containers .row .row_chart {
        max-width: 300px;
        max-height: 225px;
        height: 275px;
        width: 295px;

    }

}

@media screen and (max-width: 350px) {
    .pie_div {
        width: 325px;
        height: 325px;

    }

    .iq__fighter-stat_comparison .containers .row .row_chart {
        max-width: 275px;
        max-height: 200px;
        height: 250px;
        width: 270px;

    }

    .iq__predictions-dashboard_blue h3,
    .iq__predictions-dashboard_red h3 {
        font-size: 20px;
        padding-top: 4rem;
    }

    .iq__fighter-stats p {
        font-size: 12px;
    }

    .iq__fighter-stats h4 {
        font-size: 12px;
    }
}

@media screen and (max-width: 350px) {
    .pie_div {
        width: 300px;
        height: 300px;

    }


}