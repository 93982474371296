.iq__possibility {
    display: flex;
    flex-direction: row;

}

.iq__possibility-image {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 2rem;


}

h4.iq__sub-cta {
    font-family: var(--font-family);
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 30px;
    cursor: pointer;
    color: #FF8A71;

}

.iq__possibility-image img {
    width: 400px;
    height: 400px;
    margin: auto;

}

.iq__possibility-content {
    flex: 1;
    display: flex;
    flex-direction: column;

    max-width: 600px;
    margin: auto;
}

.iq__possibility-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71E5FF;
    cursor: pointer;
}

.iq__possibility-content h4:last-child {
    color: #FF8A71;
}

.iq__possibility-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;

    margin: 1rem 0;
}

.iq__possibility-content p {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 30px;

    color: var(--color-text);
    margin-bottom: 2rem;
    flex-wrap: wrap;
}


@media screen and (max-width: 950px) {
    .iq__possibility {
        flex-direction: column;
    }

    .iq__possibility-image {
        margin: 1rem 0;
    }

    .iq__possibility-content {
        margin-top: 2rem;
    }

}

@media screen and (min-width: 1440px) {


    .iq__possibility-image {
        justify-content: flex-end;
        padding-right: 2rem;


    }


    .iq__possibility-content {
        padding-bottom: 3rem;
    }

}

@media screen and (max-width: 550px) {



    .iq__possibility-image {
        margin: 0rem 0;
    }

    .iq__possibility-content h1 {

        font-size: 28px;
        line-height: 38px;

    }
}

@media screen and (max-width: 425px) {

    .iq__possibility-image img {
        width: 300px;
        height: 300px;

    }




}

@media screen and (max-width: 350px) {

    .iq__possibility-image img {
        width: 250px;
        height: 250px;

    }

    .iq__possibility-image {
        margin: 0rem 0;
    }

    .iq__possibility-content h1 {

        font-size: 30px;
        line-height: 40px;

    }

    .iq__possibility-content p {

        font-size: 14px;
        line-height: 26px;




    }

    .iq__possibility-content h4 {

        font-size: 15px;


    }

    .iq__possibility-content h4:last-child {
        color: #FF8A71;
    }
}

@media screen and (max-width: 300px) {

    .iq__possibility-image img {
        width: 225px;
        height: 225px;

    }

    .iq__possibility-image {
        margin: 0rem 0;
    }

    .iq__possibility-content h1 {

        font-size: 25px;
        line-height: 35px;

    }

    .iq__possibility-content p {

        font-size: 14px;
        line-height: 26px;




    }

}

@media screen and (max-width: 275px) {

    .iq__possibility-image img {
        width: 200px;
        height: 200px;

    }



    .iq__possibility-content h1 {

        font-size: 25px;
        line-height: 35px;

    }

    .iq__possibility-content p {

        font-size: 14px;
        line-height: 26px;




    }

}