
.ebook_preview-container {
    display: grid;
    grid-template-areas:"b a c";
    align-items: center;
    align-content: center;
    max-width: 100%;
    max-height: 550px;
    column-gap: 5rem;


}
.ebook_preview-headline {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    grid-area: a;
    text-align: center;
    align-items: center;

}

.ebook_preview-headline h1{

    max-width:100%;
    width: 100%;
    line-height: 45px;
    font-size: 34px;
    font-weight: 800;
    font-family: var(--font-family);

}




.ebook_preview-includes{
    display: flex;
    flex-direction: row;
    max-height: inherit;
    height: 100%;
    grid-area:c;
    margin-bottom: 3rem;
}

.ebook_preview-header-button {

    display: flex;

    margin: auto;
    margin-top: 0.5rem;
    max-width: 200px;
    width: 200px;
    font-size: 16px;
    font-family: var(--font-family);
    font-weight: 600;
    background-color: #ff4820;
    color: #fff;
    cursor: pointer;

}


.ebook_preview_alternating-colors {
    margin:auto;

    max-height: inherit;


}
.ebook_preview-img{
    grid-area: b;
    margin: auto;
    max-width: 400px;
    height: auto;
    justify-self: center;
    align-self: center;



}

.ebook_preview-img img {

    max-width: inherit;
    height: auto;

}
.ebook_preview_alternating-colors li {
    list-style-type: none ;
    color: var(--color-text);
    margin-top: 1rem;
    position: relative;
    font-size: 18px;
    line-height: 22px;


}

.ebook_preview-container div.gradient__bar {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.ebook_preview-container strong {
    color:#FFFFFF;
    font-family: var(--font-family);

}

.ebook_preview-container li p {
    color: var(--color-text);
    font-family: var(--font-family);
    font-size: 16px;

}
.ebook_preview-container .ebook_preview-book-header {
    display: flex;
    flex-direction: column;
    font-weight: 800;
    font-size: 32px;
    letter-spacing: 6px;
    margin: auto;
    font-family: var(--font-family);
    writing-mode: vertical-rl;
    text-orientation: upright;
    border: 2px solid var(--color-subtext);
    border-bottom: none;
    border-top: none;
    max-height: inherit;
    height: inherit;
    text-align: center;
    color: #FFFFFF;
    margin-right: 1rem;

}
.ebook_preview-container {
    padding: 2rem 4rem;
}
.ebook_preview-container {
    margin: 2rem 4rem;

}
@media screen and (max-width: 1600px) {



    .ebook_preview-container {

        align-items: center;
        align-content: center;
        max-width: 100%;

        column-gap:3rem;



    }
    .ebook_preview-img {

        max-width: 350px;

    }


}
@media screen and (max-width: 1450px) {

    .ebook_preview-container .ebook_preview-book-header {

        font-weight: 700;
        font-size: 30px;
        letter-spacing: 6px;
    }


    .ebook_preview-container {

        align-items: center;
        align-content: center;
        max-width: 100%;

        column-gap: 2rem;

    }

    .ebook_preview-img {

        max-width: 350px;

    }

}

@media screen and (max-width: 1350px) {
    .ebook_preview-img{

        max-width: 300px;

    }
    .ebook_preview-headline h1{

        max-width: 90%;
        line-height: 38px;


    }

    .ebook_preview-container .ebook_preview-book-header {

        font-weight: 700;
        font-size: 26px;
        letter-spacing: 10px;
    }
    .ebook_preview_alternating-colors li {

        margin-top: .75rem;



    }


    .ebook_preview-container {
        margin: 2rem 4rem;

    }
}
@media screen and (max-width: 1150px) {
    .ebook_preview-container {
        display: grid;
        grid-template-areas:"b c"
                            "a a";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;

        column-gap: 1rem;
        row-gap: 1rem;
        max-height: fit-content;
        padding: 1rem 1rem;

    }


    .ebook_preview-headline h1{
        max-width: 95%;
        line-height: 38px;

    }


    .ebook_preview-img {

        max-width: 300px;

    }
    .ebook_preview_alternating-colors  {


        margin-left: .2rem;



    }
    .ebook_preview_alternating-colors li {

        margin-top: .75rem;



    }

    .ebook_preview-includes .ebook_preview-book-header {

        font-weight: 700;
        font-size: 28px;
        letter-spacing: 12px;
        border: 2px solid var(--color-subtext);
        border-bottom: none;
        border-top: none;
        height: 100%;
        max-height: 68%;
        text-align: center;
        color: #FFFFFF;


    }

}

/* (max-width: 950px) */
@media screen and (max-width: 950px) {
    .ebook_preview-container {
        display: grid;
        grid-template-areas:"b c"
                            "a a";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;

        column-gap: 0;
        row-gap: 0;
        max-height: fit-content;
        padding: 0;

    }
    .ebook_preview-img {

        max-width: 275px;

    }

    .ebook_preview-includes {


        margin-bottom: .5rem;
    }
}
/* Tablet (max-width: 768px) */
@media screen and (max-width: 768px) {
    .ebook_preview-container {
        display: grid;
        grid-template-areas:"b"
                            "c"
                            "a";
        grid-template-columns: 1fr;
        grid-template-rows: auto;

        column-gap: 0;
        row-gap: 0;
        margin: 0;
        padding: 4rem 2rem;


    }
    .ebook_preview-img {

        max-width: 250px;

    }
    .ebook_preview-includes{
        margin-bottom: .5rem;
    }
    .ebook_preview-headline h1{

        max-width:100%;
        width: 100%;
        line-height: 45px;
        font-size: 34px;
        font-weight: 800;
        font-family: var(--font-family);

    }

    .ebook_preview-container .ebook_preview-book-header {

        font-size: 28px;
        letter-spacing: 6px;

    }

}
@media screen and (max-width: 550px) {



    .ebook_preview-headline h1{

        max-width:100%;
        width: 100%;
        line-height: 34px;
        font-size: 28px;
        font-weight: 800;
        font-family: var(--font-family);

    }
    .ebook_preview_alternating-colors li {

        font-size: 14px;
        line-height: 22px;


    }
    .ebook_preview-container .ebook_preview-book-header {

        font-size: 24px;
        letter-spacing: 6px;
        margin-right: .25rem;
    }
    .ebook_preview-container div.gradient__bar {
        width: 34px;


        margin-bottom: 0;
    }
    .ebook_preview-img {

        max-width: 225px;


    }
}