.pwd_recover-container {
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    color: var(--color-text);
    margin: auto;
    margin-top: 4rem;
    margin-bottom: 4rem;

    align-items: center;
    flex-wrap: wrap;
}

.pwd_recover-header {
    max-width: 300px;
    text-align: center;
    align-content: start;
    margin: auto;
}

.pwd_recover-header p {

    margin-bottom: 1rem;
    font-size: 15px;
}

.pwd_recover-header h1 {
    font-size: 28px;
    letter-spacing: .2rem;
    margin-bottom: -2px;
}

.pwd_recover-input {
    display: flex;
    flex-direction: column;
}

.pwd_recover-input label {
    padding-left: 3px;
    font-size: large;
}

.pwd_recover-input input {
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
    padding: 0.25rem;
    border-radius: 0.5rem;
    background-color: var(--color-footer);
    color: var(--font-color);
    line-height: 28px;
    border: 1px solid transparent;
    outline: none;
}

.pwd_recover-input input::placeholder {
    font-size: 15px;

}

.pwd_recover-input input:focus {
    border: 1px solid white;
    outline: none;
}

.pwd_recover-input button {
    max-width: 50%;
    font-size: 18px;
    margin: auto;
    margin-top: 1rem;
}

.pwd_recover-form {
    display: flex;
    flex-direction: column;
    border: solid 3px rgb(255, 255, 255);
    justify-content: space-evenly;
    border-radius: 2rem;
    box-shadow: 0 0 10px 1px #ffffff;
    max-width: fit-content;


    padding: 3rem;
    background: var(--color-footer);

    padding-top: 2rem;

    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}