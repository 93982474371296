@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');


section.show {
    display: block;
    transition: 800ms;
    opacity: 1;
    position: fixed;
    top: 25%;
}

.show.active {
    opacity: 0;
    transform: scale(0);

}

section {
    font-family: var(--font-family);
    width: 100%;
    max-width: 420px;
    max-height: 0;
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: sticky;

}



.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

form.register_form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    padding: 1rem;
    color: var(--color-text);
    background-color: var(--color-blog);
    border-radius: 2rem;
    box-shadow: 0 0 10px 2px #ffffff;

}

a,
a:visited {
    color: #fff;
}

input[type="text"],
input[type="password"],
button,
textarea {
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    padding: 0.25rem;
    border-radius: 0.5rem;
}


.formControl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.formControl h1 {
    font-size: xx-large;
    font-family: var(--big-header-font-family);
    margin-top: 0;
    -webkit-text-stroke-width: 1.25px;
    -webkit-text-stroke-color: black;
    cursor: default;

}

.formControl div {

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    max-width: 300px;
}

.formControl div p {

    max-width: inherit;
}



.formControl button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
}

.exitRegister {
    display: flex;
    justify-content: right;

}

.exitRegister span {
    display: flex;
    cursor: pointer;
}

.line label {
    cursor: pointer;
}


label {
    color: white;
}



button {
    padding: 0.5rem;
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;


}

.instructions>svg {
    margin-right: 0.25rem;
}


.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;

}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;

}

.tos_agreement {
    text-align: center;
    color: var(--color-text);
    font-size: 12px;
    width: 80%;
}

@media screen and (max-width: 525px) {
    form.register_form {

        margin: 0 1.5rem;


    }
}