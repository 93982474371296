.custom-tooltip {
    display: block;
    position: relative;
    background-color: #042c54;
    padding: 20px 40px;
    border-radius: 1rem;


    max-width: 300px;
}



.custom-tooltip h3 {
    font-size: larger;
    font-weight: 500;
    text-shadow: 2px 2px 0px #000000;
    color: antiquewhite;
}

.custom-tooltip div {
    padding-bottom: 8px;

}

.custom-tooltip .stat_header {
    font-size: large;
    font-weight: 500;
    text-shadow: 2px 2px 0px #000000;
    color: rgba(255, 255, 255, 0.85);

}


.custom-tooltip div p {

    font-family: var(--font-family-data-display);
    font-weight: 400;
    font-size: small;
    text-shadow: 1.5px 1.5px 0px #000000;
    color: rgb(133, 133, 133);
}

.custom-tooltip p b {
    color: rgb(202, 202, 202);
    font-family: var(--font-family-data-display);
    font-weight: 800;
    font-size: medium;
    text-shadow: 2px 2px 0px #000000;

}



.custom-tooltip .custom-toolip_multi-div b {
    font-size: large;

    font-family: var(--font-family-data-display);
    font-weight: 800;
    font-size: medium;
    text-shadow: 2px 2px 0px #000000;
}

.custom-tooltip .custom-toolip_multi-div p {
    font-size: large;
    color: rgb(255, 255, 255);
    font-family: var(--font-family-data-display);
    font-weight: 800;
    font-size: medium;
    text-shadow: 2px 2px 0px #000000;
}

.custom-tooltip .multi-stat_header {
    padding-bottom: 14px;

}

.custom-tooltip .custom-toolip_multi-div .custom-toolip_multi-unit p {
    font-family: var(--font-family-data-display);
    font-weight: 400;
    font-size: small;
    text-shadow: 1.5px 1.5px 0px #000000;
    color: rgb(133, 133, 133);

}

@media screen and (max-width: 620px) {}