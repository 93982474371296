.iq__prediction-display {

    max-width: 70%;
    max-height: auto;
    margin: auto;
    padding-bottom: 4rem;

}

.display_main {
    display: flex;
    flex-direction: row;
    background-color: var(--color-footer);
    width: 100%;
    height: auto;
    max-height: 400px;
    border-radius: 1rem;
    border: 10px inset black;

}

.display_main h3 {
    padding-bottom: 2px;
    font-family: var(--font-family-data-display);
    font-size: 20px;
    color: white;
    font-weight: 100;
}

.display_main p {
    font-family: var(--font-family-data-display);
    font-size: 22px;
    color: white;
    font-weight: 750;
}

.display_main h4 {
    padding-bottom: 2px;
    font-family: var(--font-family-data-display);
    color: white;
    font-size: 20px;
    font-weight: 100;
}

.display_left-section {

    align-content: center;
    justify-content: center;
    padding: .5rem;
    margin: 2px;
    background-color: var(--color-blog);
    border: rgba(255, 255, 255, 0.329) inset 2px;
    border-radius: .5rem;

}


.display_section {


    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
        "a a a a"
        "b c c c"
        "e d g h"
        "i j k l"
    ;
    text-align: center;
    justify-content: center;
    align-content: center;
}

.display_section .fighter_name {
    grid-area: a;
    display: grid;

}

.display_section .fighter_age {
    grid-area: b;
    display: grid;
}

.display_section .display_left-section.win_lose_draw {

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        "a a a"
        "b b b";


    grid-area: c;
    align-items: center;
}





.display_section .title_fight {
    grid-area: d;
    display: grid;
}


.display_section .leg_reach {
    grid-area: e;
    display: grid;
}


.display_section .five_round-count {
    grid-area: g;
    display: grid;
}

.display_section .avg_fight_time {
    grid-area: h;

    display: grid;
}

.fighter_name h3 {
    font-size: 30px;
    margin: auto;
    padding: 1rem;
    text-align: center;
    font-weight: 800;
}





.display_section .strikes_landed {
    grid-area: i;
    display: grid;
}



.display_section .strikes_attempted {
    display: grid;
    grid-area: j;
}

.display_section .takedowns_landed {
    grid-area: k;
    display: grid;
}

.display_section .takedowns_attempted {
    grid-area: l;
    display: grid;
}







.win_lose_draw .display_stat-header {
    grid-area: a;

}

.win_lose_draw .display_stat-value {
    grid-area: b;

}

.display_right-section {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25%;
    height: inherit;

    margin: 2px;
    background-color: var(--color-blog);
    border: rgba(255, 255, 255, 0.329) inset 2px;
    border-radius: .5rem;

}

.display_right-section .right_section-win_chance {
    display: flex;
    flex-direction: column;





}

.display_right-section .right_section-win_header {

    -webkit-font-smoothing: antialiased;
    stroke: #000000;
    stroke-width: 1px;
    font-weight: 750;
    font-size: 30px;
    font-family: var(--big-header-font-family);
    text-decoration: underline;
    text-decoration-thickness: 3.5px;

    -webkit-text-stroke: #000000b0 1px;
}

.display_right-section .right_section-prediction_header {
    -webkit-font-smoothing: antialiased;
    stroke: #000000;
    stroke-width: 1px;
    font-weight: 750;
    font-size: 30px;
    font-family: var(--big-header-font-family);
    text-decoration: underline;
    text-decoration-thickness: 3.5px;

    -webkit-text-stroke: #000000b0 1px;
}

.display_right-section .right_section-win_value {

    font-family: var(--big-header-font-family);
    -webkit-font-smoothing: antialiased;
    stroke: #000000;
    stroke-width: 2px;
    -webkit-text-stroke: #000000b0 2px;
    font-weight: 750;
    font-size: 40px;
    padding-top: 5px;
}

.display_right-section .right_section-prediction_value {
    font-family: var(--big-header-font-family);
    -webkit-font-smoothing: antialiased;
    stroke: #000000;
    stroke-width: 2px;
    -webkit-text-stroke: #000000b0 2px;
    font-weight: 750;
    font-size: 40px;
    padding-top: 5px;
}



.display_right-section h3 {
    margin-top: 1rem;
}


.display_right-section .right_section-prediction {
    margin-bottom: 2rem;
    flex-direction: column;
    display: flex;
}


@media screen and (max-width: 1650px) {
    .display_left-section .display_stat-header {
        font-size: 18px;
    }

    .display_left-section .display_stat-value {
        font-size: 20px;
    }

    .display_right-section .right_section-prediction_header {
        font-size: 26px;
    }

    .display_right-section .right_section-win_header {
        font-size: 26px;
    }


    .display_right-section .right_section-prediction_value {
        font-size: 33px;
    }

    .display_right-section .right_section-win_value {
        font-size: 33px;
    }

    .display_main .fighter_name h3 {
        font-size: 27px;
    }

    .iq__prediction-display {
        max-width: 80%;
    }
}


@media screen and (max-width: 1450px) {

    .display_left-section {
        max-height: inherit;
    }




    .display_left-section .display_stat-header {
        font-size: 20px;
    }

    .display_left-section .display_stat-value {
        font-size: 20px;
    }

    .display_right-section .right_section-prediction_header {
        font-size: 26px;
    }

    .display_right-section .right_section-win_header {
        font-size: 26px;
    }


    .display_right-section .right_section-prediction_value {
        font-size: 33px;
    }

    .display_right-section .right_section-win_value {
        font-size: 33px;
    }
}


@media screen and (max-width: 1350px) {

    .display_left-section {
        max-height: inherit;
    }




    .display_left-section .display_stat-header {
        font-size: 20px;
    }

    .display_left-section .display_stat-value {
        font-size: 20px;
    }

    .display_right-section .right_section-prediction_header {
        font-size: 26px;
    }

    .display_right-section .right_section-win_header {
        font-size: 26px;
    }


    .display_right-section .right_section-prediction_value {
        font-size: 33px;
    }

    .display_right-section .right_section-win_value {
        font-size: 33px;
    }
}

@media screen and (max-width: 1250px) {

    .display_left-section {
        max-height: inherit;
    }



    .display_left-section .display_stat-header {
        font-size: 20px;
    }

    .display_left-section .display_stat-value {
        font-size: 20px;
    }

    .display_right-section .right_section-prediction_header {
        font-size: 26px;
    }

    .display_right-section .right_section-win_header {
        font-size: 26px;
    }


    .display_right-section .right_section-prediction_value {
        font-size: 33px;
    }

    .display_right-section .right_section-win_value {
        font-size: 33px;
    }
}

@media screen and (max-width: 1050px) {

    .display_right-section .right_section-prediction_header {
        font-size: 25px;
    }

    .display_right-section .right_section-win_header {
        font-size: 25px;
    }


    .display_right-section .right_section-prediction_value {
        font-size: 32px;
    }

    .display_right-section .right_section-win_value {
        font-size: 32px;
    }


    .display_main h3 {
        font-family: var(--font-family-data-display);
        font-size: 18px;
        color: white;
    }

    .display_main p {
        font-family: var(--font-family-data-display);
        font-size: 20px;
        color: white;
    }

    .display_main h4 {
        font-family: var(--font-family-data-display);
        color: white;
        font-size: 18px;
    }

    .display_main .fighter_name h3 {
        font-size: 30px;
    }

    .display_left-section .display_stat-header {
        font-size: 18px;
    }

    .display_left-section .display_stat-value {
        font-size: 18px;
    }

}

@media screen and (max-width: 1000px) {







    .display_main .fighter_name h3 {
        font-size: 25px;
    }

    .display_right-section .right_section-prediction_header {
        font-size: 24px;
    }

    .display_right-section .right_section-win_header {
        font-size: 24px;
    }


    .display_right-section .right_section-prediction_value {
        font-size: 30px;
    }

    .display_right-section .right_section-win_value {
        font-size: 30px;
    }

    .display_left-section {
        padding: .5rem;
        margin: 2px;
    }

    .display_left-section .display_stat-header {
        font-size: 17px;
    }

    .display_left-section .display_stat-value {
        font-size: 17px;
    }
}

@media screen and (max-width: 900px) {

    .display_main {
        display: flex;
        flex-direction: column;

        max-height: 500px;

        border: 7px inset black;

    }

    .iq__prediction-display {
        max-width: 85%;
    }

    .display_main .fighter_name h3 {
        font-size: 25px;
    }

    .display_right-section .right_section-prediction_header {
        grid-area: a;
    }

    .display_right-section .right_section-win_header {
        grid-area: b;
    }

    .display_right-section .right_section-prediction_header,
    .display_right-section .right_section-win_header {

        font-size: 30px;
    }



    .display_right-section .right_section-prediction_value {
        grid-area: a;
    }

    .display_right-section .right_section-win_value {
        grid-area: a;
    }

    .display_right-section .right_section-prediction_value,
    .display_right-section .right_section-win_value {
        font-size: 35px;
    }



    .display_left-section {
        padding: .2rem;
        margin: 2px;
    }

    .display_left-section .display_stat-header,
    .display_left-section .display_stat-value {
        font-size: 17px;
    }


    .display_right-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            "a b"
            "c c"


        ;
        justify-content: center;

        align-self: center;
        align-content: center;
        width: 99%;

        margin: 1px;
        grid-area: m;


    }

    .display_section {


        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            "a a a a"
            "b c c f"
            "e d g h"
            "i j k l"
            "m m m m"

        ;
        text-align: center;
        justify-content: center;
        align-content: center;
    }
}

@media screen and (max-width: 850px) {



    .iq__prediction-display {
        max-width: 90%;
    }

    .display_main .fighter_name h3 {
        font-size: 24px;
    }


    .display_right-section .right_section-prediction_header,
    .display_right-section .right_section-win_header {

        font-size: 28px;
    }



    .display_right-section .right_section-prediction_value {
        grid-area: a;
    }

    .display_right-section .right_section-win_value {
        grid-area: a;
    }

    .display_right-section .right_section-prediction_value,
    .display_right-section .right_section-win_value {

        font-size: 32px;
    }



    .display_left-section {
        padding: .4rem 0;
        margin: 2px;
    }

    .display_left-section .display_stat-header,
    .display_left-section .display_stat-value {
        font-size: 16px;
    }


    .display_right-section {



        margin: 3px;



    }
}

@media screen and (max-width: 800px) {
    .display_left-section {
        padding: .35rem 0;
        margin: 2px;
    }

    .display_main .fighter_name h3 {
        font-size: 22px;
        padding: .3rem;
    }

    .display_left-section .display_stat-header {
        font-size: 16px;
    }

    .display_left-section .display_stat-value {
        font-size: 18px;
    }

    .display_right-section .right_section-prediction_value,
    .display_right-section .right_section-win_value {

        font-size: 30px;
    }

    .display_right-section .right_section-prediction_header,
    .display_right-section .right_section-win_header {

        font-size: 26px;
    }
}

@media screen and (max-width: 750px) {
    .display_left-section {
        padding: .3rem 0;

    }

    .display_main .fighter_name h3 {
        font-size: 21px;
        padding: .3rem;
    }

    .display_left-section .display_stat-header {
        font-size: 16px;
    }

    .display_left-section .display_stat-value {
        font-size: 18px;
    }

    .display_right-section .right_section-prediction_value,
    .display_right-section .right_section-win_value {

        font-size: 29px;
    }

    .display_right-section .right_section-prediction_header,
    .display_right-section .right_section-win_header {

        stroke-width: 1.5px;

        -webkit-text-stroke: #000000b0 1.5px;
        font-size: 25px;
    }
}

@media screen and (max-width: 700px) {
    .display_left-section {
        padding: .225rem 0;

    }

    .display_main .fighter_name h3 {
        font-size: 20px;

    }

    .display_left-section .display_stat-header {
        font-size: 15px;
    }

    .display_left-section .display_stat-value {
        font-size: 17px;
    }



    .display_right-section .right_section-prediction_header,
    .display_right-section .right_section-win_header {

        font-size: 24px;
    }

    .display_right-section .right_section-prediction_value,
    .display_right-section .right_section-win_value {

        stroke-width: 1.5px;

        -webkit-text-stroke: #000000b0 1.5px;
        font-size: 28px;
    }
}

@media screen and (max-width: 480px) {
    .display_left-section {
        padding: .2rem .1rem;

    }

    .display_main .fighter_name h3 {
        font-size: 18px;

    }

    .display_left-section .display_stat-header {
        font-size: 13px;
    }

    .display_left-section .display_stat-value {
        font-size: 13px;
    }



    .display_right-section .right_section-prediction_header,
    .display_right-section .right_section-win_header {

        font-size: 21px;
    }

    .display_right-section .right_section-prediction_value,
    .display_right-section .right_section-win_value {

        stroke-width: 1.5px;

        -webkit-text-stroke: #000000b0 1.5px;
        font-size: 27px;
    }
}


@media screen and (max-width: 425px) {
    .display_left-section {
        padding: .2rem 1px;

    }

    .display_main .fighter_name h3 {
        font-size: 17px;

    }

    .display_left-section .display_stat-header {
        font-size: 12px;
    }

    .display_left-section .display_stat-value {
        font-size: 12px;
    }



    .display_right-section .right_section-prediction_header,
    .display_right-section .right_section-win_header {

        font-size: 20px;
        stroke-width: 1px;

        -webkit-text-stroke: #000000b0 1px;
    }


}

@media screen and (max-width: 375px) {
    .display_section {


        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            "a a a a"
            "c c c c"
            "b b f f"
            "e e d d"
            "g g h h"
            "i i j j"
            "k k l l"
            "m m m m"

        ;
        text-align: center;
        justify-content: center;
        align-content: center;
    }


    .iq__prediction-display {

        max-width: 100%;
        max-height: auto;
        margin: auto;
        padding-bottom: 4rem;

    }

    .display_main .fighter_name h3 {
        font-size: 14px;

    }

    .display_left-section .display_stat-header {
        font-size: 12px;
    }

    .display_left-section .display_stat-value {
        font-size: 12px;

    }

    .display_right-section .right_section-prediction_value,
    .display_right-section .right_section-win_value {

        font-size: 21px;
    }

    .display_right-section .right_section-prediction_header,
    .display_right-section .right_section-win_header {
        font-size: 15px;
        text-decoration-thickness: 2px;
    }
}