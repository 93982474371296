.landing-page {
    margin-bottom: 2rem;
    margin: auto;

}
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;

}




.alternating-colors li{
    list-style-type: none ;
    color: var(--color-text);
    grid-area: c;
    margin-top: 24px;
    position: relative;
    font-size: 18px;
    line-height: 22px;

}

strong {
    color:#FFFFFF;

}



.landing-page-header{
    display: flex;
    background-color: #0a1c38;
    margin-bottom:4rem;


}
.landing-page-header-fightxiq {
    color: #FFFFFF;

}
.landing-page-header-x {
    color: #ff0d0d;
}
.landing-page-header-row p{
    font-size: 18px;
    color: white;

}
.landing-page-header-column{


    margin: auto;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex:1;
    align-self: center;



}

.landing-page-header-row button {
    flex: .60;
    width: inherit;
    min-height: 50px;
    background: #FF4820;
    font-size: 20px;
    line-height: 27px;
    cursor: pointer;
    outline: none;
    color: #fff;
    padding: 0 1rem;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.landing-page-header-column input {

    flex: 2;
    min-height: 50px;
    font-size: 20px;
    line-height: 27px;
    width: inherit;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #FFFFFF;
    font-family: var(--font-family);

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.landing-page-header-column h1{
    font-family: var(--font-family);
    text-align: center;
    align-self: center;
    font-size: 42px;
    max-width: 70%;
    color: #FFFFFF;
    margin: auto;
    line-height: 42px;
    margin-bottom: 1rem;
}
.landing-page-header-column .label_file_select{
    margin: auto;
    padding-top: .5rem;
}
.landing-page-header-column p{
    justify-self: center;
    align-self: center;
    text-align: center;
    font-size: 18px;
    color: var(--color-text);
    max-width: 70%;
    margin: auto;
}
.landing-page-header-column img {
    width: 95%;
    height: auto;

}
.landing-page-header-column-input {
    margin: auto;
    margin-top: 2rem;
    max-width: 60%;
    align-self: center;
    width: 100%;
}
.landing-page-header-column-input button {
    margin: auto;
    margin-bottom: 1.5rem;

    align-self: center;
    justify-self: center;

}
.landing-page-header-column-input input {
    margin-bottom: 1rem;


}



.landing-page-header-row{
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: auto;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1920px;

}
.landing-page-header-file-selection {
    display: flex;
    flex-direction: row;
    align-self: center;

}
.landing-page-header-file-selection a.file-selection-button {
    max-width: 200px;
    height: auto;
    width: 100%;
    background: #FF4820;
    margin: .1rem .5rem;
    padding: 1rem;
    border-radius: .5rem;
    cursor: pointer;
    font-family: var(--font-family);
}
.landing-page-header-file-selection a.file-selection-button h2{

    margin: .1rem .1rem;
    padding: .5rem;
    font-size: 17px;

    cursor: pointer;
    font-family: var(--font-family);
}
.landing-page-content_chapters {
    display: grid;

    grid-template-areas:"a a a"
                        "b c c"
                        "d . .";

    align-items: center;
    justify-items: center;

}


h4.landing-page-content-action {
    font-family: var(--font-family);
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 30px;

    cursor: pointer;
    color: #FF8A71;
    grid-area: d;
    margin: auto;
    text-align: center;


}

.landing-page-content_chapters h2 div.gradient__bar{
    max-width: inherit;
    width: inherit;
}
.landing-page-content-img {
    grid-area: b;
    max-width: 500px;
    margin: 4rem;

    height: auto;
    justify-self: center;

}


.landing-page-content-img img {
    padding: 1rem;
    max-width: inherit;
    height: auto;
}

.landing-page-content_chapters div.gradient__bar {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.landing-page-content-header h2{
    font-size: 34px;
    font-family: var(--font-family);
    font-weight: 800;
    line-height: 45px;

}

.landing-page-content_chapters .landing-page-content-header {
    display: flex;
    grid-area: a;
    align-self: center;
}

.landing-page-header-column-input button:disabled {
    opacity: 50%;
}
/* Responsive Styles */

/* Tablet (max-width: 768px) */
@media screen and (max-width: 1600px) {


    .landing-page-header{

        padding-bottom: 2rem;

    }

}
@media screen and (max-width: 1550px) {
.landing-page-header-column h1 {
    font-size: 38px;
    line-height: 38px;
    }

.landing-page-content-img img {
        padding: 2rem;

    }
}

@media screen and (max-width: 1350px) {
    .landing-page-header-column h1 {
        font-size: 34px;
        line-height: 34px;
    }
    .landing-page-header-column p {
        font-size: 16px;
    }
    .landing-page-header-column-input {
        margin-top: 1rem;

    }

    .landing-page-header-column input {

        min-height: 40px;
        font-size: 18px;
        line-height: 25px;
        padding: 0 .5rem;
        outline: none;


    }
    .landing-page-header-row button {

        min-height: 40px;
        font-size: 18px;
        line-height: 25px;
        padding: 0 .5rem;

    }

    .landing-page-header-file-selection a.file-selection-button {
        max-width: 150px;

        width: 100%;
        margin: .1rem .2rem;
        padding: .70rem;
        border-radius: .25rem;
        font-size: 11px;


    }
    .landing-page-header{

             margin-bottom:2rem;


    }


    .landing-page-content-header h2 {

        font-size: 34px;
        line-height: 45px;
        font-weight: 800;
        max-width: 510px;
    }
    h4.landing-page-content-action {
        font-size: 15px;
        line-height: 22px;
    }


    .landing-page-content_chapters div.gradient__bar {
         width: 34px;
         margin-bottom: 0.15rem;
     }
}
/* Tablet (max-width: 1200px) */
@media screen and (max-width: 1200px) {

    .landing-page-header-column h1 {
        font-size: 30px;
        line-height: 30px;
    }
    .landing-page-header-column p {
        font-size: 16px;
        line-height: 18px;
    }
    .landing-page-header-column-input {
        margin-top: 1rem;

    }

    .landing-page-header-column input {

        min-height: 35px;
        font-size: 18px;
        line-height: 25px;
        padding: 0 .35rem;


    }
    .landing-page-header-row button {

        min-height: 35px;
        font-size: 18px;
        line-height: 25px;
        padding: 0rem .35rem;

    }

    .landing-page-header-file-selection a.file-selection-button {
        max-width: 150px;
        height: auto;
        width: 100%;
        margin: 0 .2rem;
        padding: .60rem;
        border-radius: .22rem;




    }
    .landing-page-header-file-selection a.file-selection-button h2{
        font-size: 14px;




    }
    .landing-page-header-column .label_file_select{
        margin: auto;
        padding-top: .25rem;
        font-size: 16px;
    }


    .landing-page-content-img {
        max-width: 425px;
    }



    h4.landing-page-content-action {
        font-size: 16px;
        line-height: 22px;
    }

    .alternating-colors li{
        margin-top: 20px;


    }
    .alternating-colors li strong{
        font-weight: 800;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.04em;


    }
    .landing-page-content_chapters div.gradient__bar {
        width: 34px;
        margin-bottom: 0.15rem;
    }
    .landing-page.section__margin { margin: 2rem 4rem
    }
    .landing-page.section__padding { margin: 2rem 4rem
    }

}
/* Tablet (max-width: 1100px) */
@media screen and (max-width: 1100px) {
    .grid-a {
        grid-area: a;
    }
    .grid-b {
        grid-area: b;
    }

    .landing-page-header-column {

        max-width:90%;
        align-items: center;

    }

    .landing-page-header-row {

        margin: auto;
        grid-template-areas: "b"
                             "a";
        grid-template-columns: 1fr;
        display: grid;
        padding: 0 6rem;
    }

    .landing-page-header-column h1 {
        font-size: 30px;
        line-height: 34px;
        max-width: 95%;
    }





    .landing-page-header-column input,
    .landing-page-header-row button {
        min-height: 45px;
        font-size: 18px;
        line-height: 27px;

    }
    .landing-page-header-row button {

        margin-top: 0.5rem;
    }
    .landing-page-header-file-selection a.file-selection-button {
        max-width: 200px;
        height: auto;
        width: 100%;
        margin: 0 .3rem;
        padding: .70rem;
        border-radius: .22rem;




    }
    .landing-page-header-file-selection a.file-selection-button h2{
        font-size: 16px;


    }
    .landing-page-header-column .label_file_select{
        margin: auto;
        padding-top: .25rem;
        font-size: 16px;
    }

    .landing-page-header-column img {
        width: 75%;
        height: auto;

    }

    .landing-page-content-img {

        max-width: 400px;
    }


    h4.landing-page-content-action {
        font-size: 16px;
        line-height: 22px;
    }

    .alternating-colors li{
        margin-top: 16px;


    }
    .landing-page-content_chapters div.gradient__bar {
        width: 34px;
        margin-bottom: 0.15rem;
    }
    .landing-page.section__margin { margin: 1rem 2rem
    }
    .landing-page.section__padding { padding: 1rem 2rem
    }

}

/* (max-width: 950px) */
@media screen and (max-width: 950px) {



    .landing-page-content-img {
        margin: 2rem;
        max-width: 350px;
    }



    .landing-page-content-img img{


        padding: 0;
    }



    h4.landing-page-content-action {
        font-size: 16px;
        line-height: 22px;
    }

    .alternating-colors li{
        margin-top: 12px;


    }
    .landing-page-content_chapters div.gradient__bar {
        width: 30px;
        margin-bottom: 0.15rem;
    }
    .landing-page-content_chapters {
        column-gap: 1rem;


    }

}
/* (max-width: 850px) */
@media screen and (max-width: 850px) {

    .landing-page-content_chapters {
        grid-template-areas:
            "a"
            "b"
            "c"
            "d";
        align-items: center;
        justify-items: center;
        padding: 2rem 1rem;
    }


    .landing-page-content_chapters ol{
       text-align: center;
        padding-bottom: 2rem;
        margin: auto;
    }
    .landing-page-content_chapters ol li div{

        align-self: center;
        justify-self: center;
        margin: auto;
    }


}


/* Tablet (max-width: 768px) */
@media screen and (max-width: 768px) {
    .landing-page-header {
        flex-direction: column;
        margin-bottom: 2rem;
    }

    .landing-page-header-column {
        flex: 1;
        margin: 0 1rem;
        max-width: 95%;
        align-items: center;
    }

    .landing-page-header-row {
        grid-template-columns: 1fr;
        padding: 0 1rem;
    }



    .landing-page-header-column h1 {
        font-size: 32px;
        line-height: 36px;
        max-width: 90%;
    }

    .landing-page-header-column input,
    .landing-page-header-row button {
        min-height: 40px;
        font-size: 16px;
        padding: 0.5rem 1rem;
    }
    .landing-page-header-file-selection a.file-selection-button {
        height: auto;
        width: 100%;
        margin: 0 .4rem;
        padding: .70rem;
        border-radius: .22rem;
        font-size: 12px;



    }
    .landing-page-header-file-selection a.file-selection-button h2{
        max-width: 125px;
        height: auto;

        margin: 0 .1rem;
        padding: .20rem;

        font-size: 12px;



    }
    .landing-page-header-column .label_file_select{
        margin: auto;
        padding-top: .25rem;
        font-size: 14px;
    }
    .landing-page-header-column img {
        width: 65%;
        height: auto;

    }
    .landing-page-content-img{
        max-width: 250px;
    }
}



/* Mobile (max-width: 480px) */
@media screen and (max-width: 480px) {
    .landing-page-header {
        margin-bottom: 1rem;
    }

    .landing-page-header-column h1 {
        font-size: 24px;
        line-height: 28px;
        max-width: 100%;
    }

    .landing-page-header-column p {
        font-size: 13px;
        max-width: 100%;
        line-height: 15px;
    }

    .landing-page-header-column input,
    .landing-page-header-row button {
        font-size: 14px;
        padding: 0.5rem;
    }

    .landing-page-content_chapters {
        grid-template-areas:
            "a"
            "b"
            "c"
            "d";
        align-items: start;
        justify-items: start;
    }
    .landing-page-header-file-selection a.file-selection-button {
        max-width: 100px;
        height: auto;
        width: 100%;
        margin:  .2rem;
        padding: .2rem;
        border-radius: .22rem;




    }
    .landing-page-header-file-selection a.file-selection-button h2{

        margin:  .1rem;
        padding: .1rem;

        font-size: 12px;



    }
    .landing-page-header-column .label_file_select {
        margin: auto;
        padding-top: .1rem;
        font-size: 12px;
    }
    .landing-page-content-header {

        align-self: center;
        justify-self: center;
        margin: auto;
    }

    .alternating-colors li strong{
        font-weight: 800;
        font-size: 16px;
        line-height: 24px;


    }
    .alternating-colors li p{
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.04em;


    }
    .landing-page-content-header h2 {
        font-size: 28px;
        line-height: 30px;

    }

    h4.landing-page-content-action {
        font-size: 14px;
        line-height: 20px;
    }

    .landing-page-content-img{
        max-width: 225px;
    }

    h4.landing-page-content-action {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 1rem;
    }
    .landing-page-header-column-input {
        max-width: 70%;
    }

}

@media screen and (max-width: 350px) {
    .landing-page-content-img{
        max-width: 175px;
    }
    h4.landing-page-content-action {
        font-size: 14px;
        line-height: 20px;
    }
    .landing-page-content-header h2 {
        font-size: 26px;
        line-height: 30px;

    }

    .landing-page-content_chapters {

        padding: 1rem 0;
    }
    .landing-page-header-column-input {
        max-width: 80%;
    }
    .landing-page-header-column input,
    .landing-page-header-row button {
        font-size: 14px;
        padding: 0.25rem;
        min-height: 35px;
    }

}