.iq__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem;
    margin-bottom: 2rem;
}

.iq__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.iq__navbar-links_logo {
    margin-right: 2rem;
    padding: 1rem 0;

}

.iq__navbar-links_logo img {

    width: 300px;

}

.iq__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.iq__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


.iq__navbar-links_container p,
.iq__navbar-sign p,
.iq__navbar-menu_container p {
    color: aliceblue;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;


}

.iq__navbar-sign button,
.iq__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #ff4820;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    border-radius: 5px;
    border: white solid 1.5px;

    cursor: pointer;
}


.iq__navbar-menu {
    margin-left: 1rem;
    z-index: 1;
    position: relative;
}

.iq__navbar-menu_public {
    margin-left: 1rem;
    z-index: 1;
    position: relative;
    display: none;
}

.iq__navbar-menu_logged-in {
    margin-left: 1rem;
    z-index: 1;
    display: flex;
    position: relative;
}

.iq__navbar-menu svg {
    cursor: pointer;
}

.iq__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 1rem;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.iq__navbar-menu_containter-links {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 1rem;
    border: #808080 solid 1px;

}


.iq__navbar-menu_container p {
    margin: .5rem 0;
    font-size: larger;

}

.iq__navbar-menu_container-links-sign {
    display: flex;
    font-size: larger;

}

@media screen and (max-width: 1050px) {


    .iq__navbar-menu {
        display: flex;
    }

    .iq__navbar-links_container p {
        color: aliceblue;
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;


        margin: 0 .5rem;



    }
}

@media screen and (max-width: 950px) {


    .iq__navbar-links_container {
        display: none;

    }

    .iq__navbar-links_logo img {

        width: 250px;

    }


    .iq__navbar-sign p,
    .iq__navbar-sign button {
        display: none;

    }

    .iq__navbar-menu_containter-links p,
    .iq__navbar-menu_containter-links button {
        display: flex;
    }


    .iq__navbar-menu_public {
        margin-left: 1rem;
        z-index: 1;
        position: relative;
        display: flex;
    }
}

@media screen and (max-width: 800px) {
    .iq__navbar-links_logo img {

        width: 250px;

    }



    .iq__navbar-links_container {
        display: none;

    }




}

@media screen and (max-width: 700px) {
    .iq__navbar-links_logo img {

        width: 225px;

    }

    .iq__navbar {
        padding: 2rem 4rem;

    }
}

@media screen and (max-width: 550px) {

    .iq__navbar {
        padding: 2rem;

    }





    .iq__navbar-links_logo img {

        width: 200px;

    }
}

@media screen and (max-width: 350px) {

    .iq__navbar {
        padding: .5rem;

    }





    .iq__navbar-links_logo img {

        width: 175px;

    }
}

@media screen and (max-width: 300px) {

    .iq__navbar {
        padding: .5rem;

    }





    .iq__navbar-links_logo img {

        width: 150px;

    }
}