.pay_card_input .checkbox_confirm label {
    color: var(--color-text);
    display: flex;
    flex-direction: row;
    font-size: large;
    width: fit-content;
    opacity: 50%;
    margin: auto;
    align-items: center;
}

.pay_card_input .checkbox_confirm label input {
    opacity: 100%;
    margin-right: .25rem;

}

.billing_input img {
    padding-bottom: 1rem;
}

.billing_update-button {
    font-weight: 700;
    cursor: pointer;
}

.billing_update-button:hover {
    opacity: 80%;
}